import { API, API_ENUM, METHODS, SCHEMAS } from "@shared/constants";
import { SchemasMap } from "@shared/interfaces";
import { mapToSchema } from "@shared/utils";

const schemaShapes: SchemasMap<keyof typeof API.BILLING> = {
  [API_ENUM.GET_BILLING_PORTAL_URL]: {
    request: {
      name: `${API.BILLING.GET_BILLING_PORTAL_URL}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: {},
    },
    response: {
      name: `${API.BILLING.GET_BILLING_PORTAL_URL}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: {},
    },
  },
};

export default mapToSchema(schemaShapes);
