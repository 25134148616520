import { all, fork } from "redux-saga/effects";
import { saga as authSaga } from "@containers/Auth/store";
import { saga as sharedSaga } from "@shared/store";
import { saga as channelSaga } from "@containers/Channel/store";
import { saga as topicSaga } from "@containers/Topic/store";
import { saga as billingSaga } from "@containers/Billing/store";

const allSagas = [authSaga, sharedSaga, channelSaga, topicSaga, billingSaga];

export default function* appSagas() {
  yield all(allSagas.map(fork));
}
