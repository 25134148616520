import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { MenuItem } from "@shared/interfaces";
import { User, Workspace } from "@shared/models";
import { Icon } from "@shared/components";
import { useOutsideClick } from "@shared/hooks";

import { Item } from "./Item";

import "./index.scss";

export interface SidebarProps {
  items: MenuItem[];
  supportItems: MenuItem[];
  user: User | null;
  workspace: Workspace | null;
  activePath?: string;
  onLogout: () => void;
}

const Sidebar: FC<SidebarProps> = (props) => {
  const { items, supportItems, activePath, user, workspace, onLogout } = props;
  const [showLogout, setShowLogout] = useState(false);

  const wrapperRef = useRef(null);
  const { isOutside } = useOutsideClick(wrapperRef);

  useEffect(() => {
    setShowLogout(false);
  }, [isOutside]);

  const renderItems = useMemo(() => {
    if (!user) {
      return [];
    }

    return items.map((item) => {
      const itemPath = item.path;
      const isActiveItem = activePath?.includes(itemPath);
      return (
        <Item
          active={isActiveItem}
          path={itemPath}
          name={item.name}
          key={item.name}
          iconName={item.iconName}
          iconNameActive={item.iconNameActive}
        />
      );
    });
  }, [items, activePath, user]);

  return (
    <div className="sidebar">
      <div className="sidebar-fixed">
        <div className="sidebar-header">
          <div className="sidebar-logo" />
          <div className="sidebar-title" />
        </div>
        <div className="sidebar-content">{renderItems}</div>
        {user && (
          <div className="sidebar-footer">
            <div className="support-links">
              <Item
                active={activePath?.includes(supportItems[0].path)}
                path={supportItems[0].path}
                name={supportItems[0].name}
                iconName={supportItems[0].iconName}
                iconNameActive={supportItems[0].iconNameActive}
              />
            </div>
            {!showLogout ? (
              <div className="user-info-wrapper" onClick={() => setShowLogout(true)}>
                {user.avatar ? (
                  <div className="avatar">
                    <img src={user.avatar} alt={user.first_name} />
                  </div>
                ) : null}
                <div className="user-info">
                  <div className="user-name">
                    {user.first_name} {user.last_name}
                  </div>
                  {workspace ? (
                    <div className="workspace">
                      <span className="workspace-name">{workspace.name}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className="logout-wrapper" ref={wrapperRef}>
                <div className="logout-group" onClick={() => onLogout()}>
                  <Icon className="logout-icon" type="logout" />
                  <div className="logout">Log Out</div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
