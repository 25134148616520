export enum AuthActionTypes {
  LOGIN = "@@AUTH/LOGIN",
  LOGIN_SUCCESS = "@@AUTH/LOGIN_SUCCESS",
  LOGIN_FAILURE = "@@AUTH/LOGIN_FAILURE",

  LOGOUT = "@@AUTH/LOGOUT",
  LOGOUT_SUCCESS = "@@AUTH/LOGOUT_SUCCESS",
  LOGOUT_FAILURE = "@@AUTH/LOGOUT_FAILURE",

  ADD_SLACK_WORKSPACE = "@@AUTH/ADD_SLACK_WORKSPACE",
  ADD_SLACK_WORKSPACE_SUCCESS = "@@AUTH/ADD_SLACK_WORKSPACE_SUCCESS",
  ADD_SLACK_WORKSPACE_FAILURE = "@@AUTH/ADD_SLACK_WORKSPACE_FAILURE",

  AUTH_HASH_TOKEN = "@@AUTH/AUTH_HASH_TOKEN",
  AUTH_HASH_TOKEN_SUCCESS = "@@AUTH/AUTH_HASH_TOKEN_SUCCESS",
  AUTH_HASH_TOKEN_FAILURE = "@@AUTH/AUTH_HASH_TOKEN_FAILURE",
}
