import React, { useCallback } from "react";
import classnames from "classnames";

import { TableProperty } from "../interface";
import "./index.scss";

export interface RowProps<T> {
  item: T;
  properties: TableProperty<T>[];
  onClick?: () => void;
}

function getItemCellContent<T>(item: T, prop: TableProperty<T>) {
  return prop.get ? prop.get(item) : item[prop.key];
}

function Row<T>(props: RowProps<T>) {
  const { properties, item, onClick } = props;

  const localCellClick = useCallback(
    (property: TableProperty<T>, event: React.MouseEvent) => {
      if (!property.cellClick) {
        return;
      }

      event.stopPropagation();
      event.preventDefault();

      property.cellClick(item, event);
    },
    [item],
  );

  return (
    <div className="table-row" onClick={onClick}>
      {properties.map((prop, index) => (
        <React.Fragment key={index}>
          <div key={prop.title} className={classnames("table-row-cell", prop.cellClassName)}>
            {prop.cellClickBlock && (
              <div className={classnames("table-row-cell-clickblock")} onClick={(e) => localCellClick(prop, e)}>
                {prop.cellClickBlock}
              </div>
            )}
            {getItemCellContent<T>(item, prop)}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}

export default Row;
