import { User, Workspace } from "@shared/models";
import { actions } from "@shared/store";
import { call, put, takeLatest, select, takeEvery } from "redux-saga/effects";
import { AnyAction } from "redux";
import { ActionWithPayload, AppState, Stripe } from "@shared/interfaces";
import { tokenHandler } from "@shared/utils";

import api from "../api";

function* getUserSaga() {
  try {
    yield put(actions.startLoading());
    const user: User = yield call(api.getUser);
    yield tokenHandler.setUser(user);
    yield put(actions.getUser.success(user));
    yield put(actions.stopLoading());
  } catch (error) {
    yield put(actions.stopLoading());
    yield put(actions.getUser.failure(error as Error));
  }
}

function* getWorkspaceSaga() {
  try {
    yield put(actions.startLoading());
    const response: Workspace = yield call(api.getWorkspace);
    yield put(actions.getWorkspace.success(response));
    yield put(actions.stopLoading());
  } catch (error) {
    yield put(actions.getWorkspace.failure(error as Error));
    yield put(actions.stopLoading());
  }
}

function* getStripeUrlSaga({ payload }: ActionWithPayload<Stripe>) {
  try {
    yield put(actions.startLoading());
    const response: { url: string } = yield call(api.getStripeUrl, payload);
    yield put(actions.getStripeUrl.success(response));
    yield put(actions.stopLoading());
  } catch (error) {
    yield put(actions.getStripeUrl.failure(error as Error));
    yield put(actions.stopLoading());
  }
}

function* getStripeDiscountUrlSaga({ payload }: ActionWithPayload<Stripe>) {
  try {
    yield put(actions.startLoading());
    const response: { url: string } = yield call(api.getStripeUrl, payload);
    yield put(actions.getStripeDiscountUrl.success(response));
    yield put(actions.stopLoading());
  } catch (error) {
    yield put(actions.getStripeDiscountUrl.failure(error as Error));
    yield put(actions.stopLoading());
  }
}

function* watchLoaders(payload: AnyAction) {
  const state: AppState = yield select();
  const { loadingTypes } = state.shared;

  const startedSection = loadingTypes.find(({ startActions }) => startActions.includes(payload.type));
  const stoppedSection = loadingTypes.find(({ stopActions }) => stopActions[payload.type]);

  if (startedSection) {
    yield put(actions.addLoadingSection({ loadingSection: startedSection.name, requestName: payload.type }));
  }
  if (stoppedSection) {
    yield put(
      actions.removeLoadingSection({
        loadingSection: stoppedSection.name,
        requestName: stoppedSection.stopActions[payload.type],
      }),
    );
  }
}

function* sharedSagas() {
  yield takeLatest(actions.getUser.request, getUserSaga);
  yield takeLatest(actions.getWorkspace.request, getWorkspaceSaga);
  yield takeLatest(actions.getStripeUrl.request, getStripeUrlSaga);
  yield takeLatest(actions.getStripeDiscountUrl.request, getStripeDiscountUrlSaga);

  yield takeEvery("*", watchLoaders);
}

export default sharedSagas;
