import { getRequestSchema } from "./shared.schema";

export const getChannelRequestSchema = getRequestSchema({
  id: { type: ["number", "string"] },
});

export const deleteChannelRequestSchema = getRequestSchema({
  id: { type: ["number", "string"] },
});

export const findRequestSchema = getRequestSchema({
  search: { type: "string" },
});

export const channelSchema = {
  type: "object",
  additionalProperties: false,
  required: [
    "id",
    "time_zone_offset",
    "name",
    "frequency",
    "activity_monday",
    "activity_tuesday",
    "activity_wednesday",
    "activity_thursday",
    "activity_friday",
    "activity_saturday",
    "activity_sunday",
  ],
  properties: {
    id: { type: "number" },
    time_zone_offset: { type: "number" },
    name: { type: "string" },
    frequency: { type: "string" },
    next_publish_at: { type: "string" },
    publish_time: { type: "string" },
    header: { type: "string" },
    activity_monday: { type: "boolean" },
    activity_tuesday: { type: "boolean" },
    activity_wednesday: { type: "boolean" },
    activity_thursday: { type: "boolean" },
    activity_friday: { type: "boolean" },
    activity_saturday: { type: "boolean" },
    activity_sunday: { type: "boolean" },
  },
};

export const createChannelRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["name", "time_zone", "frequency", "publish_time"],
  properties: {
    name: { type: "string" },
    time_zone: { type: "string" },
    frequency: { type: "string" },
    days: { type: "array" },
    header: { type: "array" },
    next_publish_at: { type: "string" },
    publish_time: { type: "string" },
  },
};
