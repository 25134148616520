import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import { ChannelStateType } from "../interface";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

export const initialState: ChannelStateType = {
  channels: [],
  currentChannel: null,
  slackChannels: [],
  slackUsers: [],
};

const reducer = createReducer<ChannelStateType, Action>(initialState)
  .handleAction(actions.getChannels.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.channels = action.payload;
    }),
  )
  .handleAction(actions.findSlackChannels.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.slackChannels = action.payload;
    }),
  )
  .handleAction(actions.findSlackUsers.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.slackUsers = action.payload;
    }),
  )
  .handleAction(actions.getChannel.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      nextState.currentChannel = payload;
    }),
  );
export { reducer as ChannelReducer };
