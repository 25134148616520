import React, { FC } from "react";
import { Button, BackButton, Icon, AccessControl } from "@shared/components";
import "./index.scss";
import { IconType } from "@shared/components/Icon/Icon";
import { ButtonStyle, ButtonType } from "@shared/components/Common/Button/Button";

export interface FormHeaderProps {
  title: string;
  onSubmit?: () => void;
  onBack?: () => void;
  submitText?: string;
  iconType?: IconType;
  permission?: string;
  channel_id?: number | null;
  buttonsGroup?: [
    {
      title?: string;
      onClick?: () => void;
      type?: ButtonType;
      buttonStyle?: ButtonStyle;
      className?: string;
      disabled?: boolean;
      iconType?: IconType;
    },
  ];
}

const FormHeader: FC<FormHeaderProps> = (props) => {
  const { title, onSubmit, onBack, submitText, iconType, buttonsGroup, permission, channel_id } = props;

  return (
    <div className="form-header">
      <div className="form-header-title">
        {onBack && <BackButton onClick={onBack} />}
        {iconType && (
          <span className="icon-wrapper">
            <Icon type={iconType} />
          </span>
        )}
        <div className="title">{title}</div>
      </div>
      <div className="form-header-buttons">
        {submitText && (
          <AccessControl permission={permission || ""} channel_id={channel_id ? channel_id : null}>
            <div className="form-header-submit">
              <Button type="submit" buttonStyle="bordered" buttonColor="green" onClick={onSubmit}>
                <Icon type="option-selected-green" />
                {submitText}
              </Button>
            </div>
          </AccessControl>
        )}
        {buttonsGroup?.length &&
          buttonsGroup.map((button, index) => (
            <Button
              key={index}
              type={button.type}
              buttonStyle={button.buttonStyle}
              onClick={button.onClick}
              disabled={button.disabled}
              className={button.className}>
              {button.iconType && <Icon type={button.iconType} />}
              {button.title ? button.title : null}
            </Button>
          ))}
      </div>
    </div>
  );
};

export default FormHeader;
