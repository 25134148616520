import config from "../../config";

export enum API_ENUM {
  GET_USER = "GET_USER",
  GET_WORKSPACE = "GET_WORKSPACE",
  GET_STRIPE_URL = "GET_STRIPE_URL",
  ADD_SLACK_WORKSPACE = "ADD_SLACK_WORKSPACE",
  GET_CHANNELS = "GET_CHANNELS",
  GET_CHANNEL = "GET_CHANNEL",
  FIND_SLACK_CHANNELS = "FIND_SLACK_CHANNELS",
  FIND_SLACK_USERS = "FIND_SLACK_USERS",
  CREATE_CHANNEL = "CREATE_CHANNEL",
  UPDATE_CHANNEL = "UPDATE_CHANNEL",
  DELETE_CHANNEL = "DELETE_CHANNEL",
  GET_TOPICS = "GET_TOPICS",
  GET_TOPICS_HISTORY = "GET_TOPICS_HISTORY",
  GET_PACKS = "GET_PACKS",
  GET_PACK = "GET_PACK",
  GET_PACKS_CHANNEL = "GET_PACKS_CHANNEL",
  SUBSCRIBE_CHANNEL_PACK = "SUBSCRIBE_CHANNEL_PACK",
  UNSUBSCRIBE_CHANNEL_PACK = "UNSUBSCRIBE_CHANNEL_PACK",
  UPDATE_QUEUED_TOPIC_ACTIVE = "UPDATE_QUEUED_TOPIC_ACTIVE",
  SHUFFLE_TOPICS = "SHUFFLE_TOPICS",
  DELETE_TOPIC_CHANNEL = "DELETE_TOPIC_CHANNEL",
  UPDATE_QUEUED_TOPIC_ORDER = "UPDATE_QUEUED_TOPIC_ORDER",
  CREATE_TOPIC = "CREATE_TOPIC",
  GET_TOPIC = "GET_TOPIC",
  UPDATE_TOPIC = "UPDATE_TOPIC",
  GET_BILLING_PORTAL_URL = "GET_BILLING_PORTAL_URL",
}

export default {
  AUTH: {
    LOGIN: `${config.baseApiUrl}slack/login`,
    REGISTER: `${config.baseApiUrl}auth/register`,
    FORGOT_PASSWORD: `${config.baseApiUrl}auth/forgotPassword`,
    SET_PASSWORD: `${config.baseApiUrl}auth/setPassword`,
    AUTH_HASH_TOKEN: `${config.baseApiUrl}users/auth-hash-token`,
  },
  SHARED: {
    [API_ENUM.GET_USER]: `${config.baseApiUrl}users`,
    [API_ENUM.GET_WORKSPACE]: `${config.baseApiUrl}workspaces`,
    [API_ENUM.GET_STRIPE_URL]: `${config.baseApiUrl}stripe/create-checkout-session`,
  },
  WORKSPACE: {
    [API_ENUM.ADD_SLACK_WORKSPACE]: `${config.baseApiUrl}slack/oauth_workspace`,
  },
  CHANNEL: {
    [API_ENUM.GET_CHANNELS]: `${config.baseApiUrl}channels`,
    [API_ENUM.GET_CHANNEL]: (id: number | string) => `${config.baseApiUrl}channels/${id}`,
    [API_ENUM.FIND_SLACK_CHANNELS]: `${config.baseApiUrl}channels/find`,
    [API_ENUM.FIND_SLACK_USERS]: `${config.baseApiUrl}channels/find-users`,
    [API_ENUM.CREATE_CHANNEL]: `${config.baseApiUrl}channels`,
    [API_ENUM.UPDATE_CHANNEL]: (id: number | string) => `${config.baseApiUrl}channels/${id}`,
    [API_ENUM.DELETE_CHANNEL]: (id: number | string) => `${config.baseApiUrl}channels/${id}`,
  },
  TOPIC: {
    [API_ENUM.GET_TOPICS]: (id: number | string) => `${config.baseApiUrl}queued-topics/${id}`,
    [API_ENUM.GET_TOPICS_HISTORY]: (id: number | string) => `${config.baseApiUrl}queued-topics/history/${id}`,
    [API_ENUM.SHUFFLE_TOPICS]: (id: number | string) => `${config.baseApiUrl}queued-topics/${id}/shuffle`,
    [API_ENUM.GET_PACKS]: `${config.baseApiUrl}packs`,
    [API_ENUM.GET_PACK]: (id: number | string) => `${config.baseApiUrl}packs/${id}`,
    [API_ENUM.GET_PACKS_CHANNEL]: (id: number | string) => `${config.baseApiUrl}packs/channel/${id}`,
    [API_ENUM.SUBSCRIBE_CHANNEL_PACK]: (channel_id: number | string, pack_id: number | string) =>
      `${config.baseApiUrl}channels/${channel_id}/packs/${pack_id}`,
    [API_ENUM.UNSUBSCRIBE_CHANNEL_PACK]: (channel_id: number | string, pack_id: number | string) =>
      `${config.baseApiUrl}channels/${channel_id}/packs/${pack_id}`,
    [API_ENUM.DELETE_TOPIC_CHANNEL]: (channel_id: number | string, queued_topic_id: number | string) =>
      `${config.baseApiUrl}queued-topics/remove-topic-from-channel/${channel_id}/${queued_topic_id}`,
    [API_ENUM.UPDATE_QUEUED_TOPIC_ACTIVE]: (id: number | string) =>
      `${config.baseApiUrl}queued-topics/${id}/set-is-active`,
    [API_ENUM.UPDATE_QUEUED_TOPIC_ORDER]: (id: number | string) =>
      `${config.baseApiUrl}queued-topics/${id}/change-order`,

    [API_ENUM.CREATE_TOPIC]: `${config.baseApiUrl}topics/create`,
    [API_ENUM.GET_TOPIC]: (id: number | string) => `${config.baseApiUrl}topics/${id}`,
    [API_ENUM.UPDATE_TOPIC]: (id: number | string) => `${config.baseApiUrl}topics/${id}`,
  },
  BILLING: {
    [API_ENUM.GET_BILLING_PORTAL_URL]: `${config.baseApiUrl}stripe/create-billing-portal-session`,
  },
};
