import React, { useCallback, useRef } from "react";
import classnames from "classnames";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { XYCoord } from "dnd-core";

import { TableProperty } from "../interface";
import "./index.scss";

export interface RowProps<T> {
  item: T;
  properties: TableProperty<T>[];
  onClick?: () => void;
  id?: number | string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  onDragAndDropRequest?: () => void;
}

interface DragItem {
  id: number | string;
  index: number;
  type: string;
}

function getItemCellContent<T>(item: T, prop: TableProperty<T>) {
  return prop.get ? prop.get(item) : item[prop.key];
}

function DragDropRow<T extends { is_active?: boolean }>(props: RowProps<T>) {
  const { properties, item, onClick, index, moveCard, id, onDragAndDropRequest } = props;

  const localCellClick = useCallback(
    (property: TableProperty<T>, event: React.MouseEvent) => {
      if (!property.cellClick) {
        return;
      }

      event.stopPropagation();
      event.preventDefault();

      property.cellClick(item, event);
    },
    [item],
  );

  const dropRef = useRef<HTMLDivElement>(null);
  const dragRef = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop({
    accept: "Card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!dropRef.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: "Card",
    item: () => {
      return { id, index };
    },
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      onDragAndDropRequest && onDragAndDropRequest();
    },
  });

  const opacity = isDragging ? 0.1 : 1;

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <div
      ref={dropRef}
      className={classnames("table-dnd-row", { disabled: !item.is_active })}
      onClick={onClick}
      style={{ opacity }}>
      <div ref={dragRef} className={"drag-item-image"} data-handler-id={handlerId} />
      {properties.map((prop) => (
        <div key={prop.title} className={classnames("table-row-cell", prop.cellClassName)}>
          {prop.cellClickBlock && (
            <div className={classnames("table-row-cell-clickblock")} onClick={(e) => localCellClick(prop, e)}>
              {prop.cellClickBlock}
            </div>
          )}
          {getItemCellContent<T>(item, prop)}
        </div>
      ))}
    </div>
  );
}

export default DragDropRow;
