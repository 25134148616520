import { getRequestSchema } from "./shared.schema";

export const getTopicsRequestSchema = getRequestSchema({
  id: { type: ["number", "string"] },
});

export const getTopicByIdRequestSchema = getRequestSchema({
  id: { type: ["number", "string"] },
});

export const getPackByIdRequestSchema = getRequestSchema({
  id: { type: ["number", "string"] },
});

export const getPacksChannelRequestSchema = getRequestSchema({
  id: { type: ["number", "string"] },
});

export const subscribeChannelForPackRequestSchema = getRequestSchema({
  channel_id_id: { type: ["number", "string"] },
  pack_id: { type: ["number", "string"] },
});

export const unsubscribePackFromChannelRequestSchema = getRequestSchema({
  channel_id_id: { type: ["number", "string"] },
  pack_id: { type: ["number", "string"] },
});

export const deleteTopicFromChannelRequestSchema = getRequestSchema({
  channel_id_id: { type: ["number", "string"] },
  topic_id: { type: ["number", "string"] },
});

export const updateQueuedTopicActiveRequestSchema = getRequestSchema({
  is_active: { type: "boolean" },
  channel_id: { type: ["number", "string"] },
});

export const createTopicRequestSchema = getRequestSchema({
  channel_id: { type: ["number", "string"] },
  name: { type: "string" },
  content: { type: ["string", "null"] },
  image_base_64: { type: ["string", "null"] },
  locale: { type: ["string", "null"] },
});

export const editTopicRequestSchema = getRequestSchema({
  name: { type: "string" },
  content: { type: ["string", "null"] },
  image_base_64: { type: ["string", "null"] },
});
