export enum ChannelActionTypes {
  GET_CHANNELS = "@@CHANNEL/GET_CHANNELS",
  GET_CHANNELS_SUCCESS = "@@CHANNEL/GET_CHANNELS_SUCCESS",
  GET_CHANNELS_FAILURE = "@@CHANNEL/GET_CHANNELS_FAILURE",

  GET_CHANNEL = "@@CHANNEL/GET_CHANNEL",
  GET_CHANNEL_SUCCESS = "@@CHANNEL/GET_CHANNEL_SUCCESS",
  GET_CHANNEL_FAILURE = "@@CHANNEL/GET_CHANNEL_FAILURE",

  FIND_SLACK_CHANNELS = "@@CHANNEL/FIND_SLACK_CHANNELS",
  FIND_SLACK_CHANNELS_SUCCESS = "@@CHANNEL/FIND_SLACK_CHANNELS_SUCCESS",
  FIND_SLACK_CHANNELS_FAILURE = "@@CHANNEL/FIND_SLACK_CHANNELS_FAILURE",

  FIND_SLACK_USERS = "@@CHANNEL/FIND_SLACK_USERS",
  FIND_SLACK_USERS_SUCCESS = "@@CHANNEL/FIND_SLACK_USERS_SUCCESS",
  FIND_SLACK_USERS_FAILURE = "@@CHANNEL/FIND_SLACK_USERS_FAILURE",

  CREATE_CHANNEL = "@@CHANNEL/CREATE_CHANNEL",
  CREATE_CHANNEL_SUCCESS = "@@CHANNEL/CREATE_CHANNEL_SUCCESS",
  CREATE_CHANNEL_FAILURE = "@@CHANNEL/CREATE_CHANNEL_FAILURE",

  UPDATE_CHANNEL = "@@CHANNEL/UPDATE_CHANNEL",
  UPDATE_CHANNEL_SUCCESS = "@@CHANNEL/UPDATE_CHANNEL_SUCCESS",
  UPDATE_CHANNEL_FAILURE = "@@CHANNEL/UPDATE_CHANNEL_FAILURE",

  DELETE_CHANNEL = "@@CHANNEL/DELETE_CHANNEL",
  DELETE_CHANNEL_SUCCESS = "@@CHANNEL/DELETE_CHANNEL_SUCCESS",
  DELETE_CHANNEL_FAILURE = "@@CHANNEL/DELETE_CHANNEL_FAILURE",

  CLEAR_CHANNELS = "@@CHANNEL/CLEAR_CHANNELS",
}
