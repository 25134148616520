import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import { TopicStateType } from "../interface";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

export const initialState: TopicStateType = {
  topics: [],
  currentTopic: null,
  currentPackDetailed: null,
  packs: [],
  packsChannel: [],
};

const reducer = createReducer<TopicStateType, Action>(initialState)
  .handleAction(actions.getTopics.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      nextState.topics = payload;
    }),
  )
  .handleAction(actions.getTopicsHistory.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      nextState.topics = payload;
    }),
  )
  .handleAction(actions.getTopic.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      nextState.currentTopic = payload;
    }),
  )
  .handleAction(actions.updateTopic.success, (state, action) =>
    produce(state, (nextState) => {
      const { topic } = action.payload;
      const index = nextState.topics.findIndex((m) => m.topic_id === topic.id);
      if (index >= 0) {
        nextState.topics[index].topic = topic;
      }
    }),
  )
  .handleAction(actions.getPacks.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      nextState.packs = payload;
    }),
  )
  .handleAction(actions.getPack.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      nextState.currentPackDetailed = payload;
    }),
  )
  .handleAction(actions.getPacksChannel.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      nextState.packsChannel = payload;
    }),
  )
  .handleAction(actions.createPackChannel.success, (state, action) =>
    produce(state, (nextState) => {
      const newPack = state.packs.find((pack) => pack.id === action.payload.pack_id);
      if (newPack) {
        nextState.packsChannel = [...state.packsChannel, newPack];
      }
    }),
  )
  .handleAction(actions.deletePackChannel.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.packsChannel = state.packsChannel.filter((f) => f.id !== action.payload.pack_id);
    }),
  )
  .handleAction(actions.shuffleTopics.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      nextState.topics = payload;
    }),
  );
export { reducer as TopicReducer };
