import { API_ENUM } from "@shared/constants/api";
import {
  createTopicRequestSchema,
  deleteTopicFromChannelRequestSchema,
  editTopicRequestSchema,
  getPackByIdRequestSchema,
  getPacksChannelRequestSchema,
  getTopicByIdRequestSchema,
  getTopicsRequestSchema,
  subscribeChannelForPackRequestSchema,
  unsubscribePackFromChannelRequestSchema,
  updateQueuedTopicActiveRequestSchema,
} from "@shared/validators";
import { SchemasMap } from "@shared/interfaces";
import { generateDynamicSchemaKeyString, mapToSchema } from "@shared/utils";

import { API, METHODS, SCHEMAS } from "../../../shared/constants";

const schemaShapes: SchemasMap<keyof typeof API.TOPIC> = {
  [API_ENUM.GET_TOPICS]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TOPIC.GET_TOPICS("$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: getTopicsRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TOPIC.GET_TOPICS("$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
  },
  [API_ENUM.GET_TOPIC]: {
    request: {
      name: `${API.TOPIC.GET_TOPIC}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: getTopicByIdRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TOPIC.GET_TOPIC("$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
  },
  [API_ENUM.UPDATE_TOPIC]: {
    request: {
      name: `${API.TOPIC.UPDATE_TOPIC}${SCHEMAS.REQUEST}${METHODS.PUT}`,
      schema: editTopicRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TOPIC.UPDATE_TOPIC("$id"))}${SCHEMAS.REQUEST}${METHODS.PUT}`,
      schema: {},
    },
  },
  [API_ENUM.GET_TOPICS_HISTORY]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TOPIC.GET_TOPICS_HISTORY("$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: getTopicsRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TOPIC.GET_TOPICS_HISTORY("$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
  },
  [API_ENUM.SHUFFLE_TOPICS]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TOPIC.SHUFFLE_TOPICS("$id"))}${SCHEMAS.REQUEST}${METHODS.PUT}`,
      schema: getTopicsRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TOPIC.SHUFFLE_TOPICS("$id"))}${SCHEMAS.REQUEST}${METHODS.PUT}`,
      schema: {},
    },
  },
  [API_ENUM.GET_PACKS_CHANNEL]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TOPIC.GET_PACKS_CHANNEL("$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: getPacksChannelRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TOPIC.GET_PACKS_CHANNEL("$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
  },
  [API_ENUM.SUBSCRIBE_CHANNEL_PACK]: {
    request: {
      name: `${API.TOPIC.SUBSCRIBE_CHANNEL_PACK}${SCHEMAS.REQUEST}${METHODS.PUT}`,
      schema: { subscribeChannelForPackRequestSchema },
    },
    response: {
      name: `${API.TOPIC.SUBSCRIBE_CHANNEL_PACK}${SCHEMAS.RESPONSE}${METHODS.DELETE}`,
      schema: {},
    },
  },
  [API_ENUM.UNSUBSCRIBE_CHANNEL_PACK]: {
    request: {
      name: `${API.TOPIC.UNSUBSCRIBE_CHANNEL_PACK}${SCHEMAS.REQUEST}${METHODS.DELETE}`,
      schema: { unsubscribePackFromChannelRequestSchema },
    },
    response: {
      name: `${API.TOPIC.UNSUBSCRIBE_CHANNEL_PACK}${SCHEMAS.RESPONSE}${METHODS.DELETE}`,
      schema: {},
    },
  },
  [API_ENUM.DELETE_TOPIC_CHANNEL]: {
    request: {
      name: `${API.TOPIC.DELETE_TOPIC_CHANNEL}${SCHEMAS.REQUEST}${METHODS.DELETE}`,
      schema: { deleteTopicFromChannelRequestSchema },
    },
    response: {
      name: `${API.TOPIC.DELETE_TOPIC_CHANNEL}${SCHEMAS.RESPONSE}${METHODS.DELETE}`,
      schema: {},
    },
  },
  [API_ENUM.UPDATE_QUEUED_TOPIC_ACTIVE]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TOPIC.UPDATE_QUEUED_TOPIC_ACTIVE("$id"))}${SCHEMAS.REQUEST}${
        METHODS.PUT
      }`,
      schema: updateQueuedTopicActiveRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TOPIC.UPDATE_QUEUED_TOPIC_ACTIVE("$id"))}${SCHEMAS.REQUEST}${
        METHODS.PUT
      }`,
      schema: {},
    },
  },
  [API_ENUM.UPDATE_QUEUED_TOPIC_ORDER]: {
    request: {
      name: `${API.TOPIC.UPDATE_QUEUED_TOPIC_ORDER}${SCHEMAS.REQUEST}${METHODS.PUT}`,
      schema: { updateQueuedTopicActiveRequestSchema },
    },
    response: {
      name: `${API.TOPIC.UPDATE_QUEUED_TOPIC_ORDER}${SCHEMAS.RESPONSE}${METHODS.PUT}`,
      schema: {},
    },
  },
  [API_ENUM.CREATE_TOPIC]: {
    request: {
      name: `${API.TOPIC.CREATE_TOPIC}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: { createTopicRequestSchema },
    },
    response: {
      name: `${API.TOPIC.CREATE_TOPIC}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: {},
    },
  },
  [API_ENUM.GET_PACKS]: {
    request: {
      name: `${API.TOPIC.GET_PACKS}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${API.TOPIC.GET_PACKS}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: {},
    },
  },
  [API_ENUM.GET_PACK]: {
    request: {
      name: `${API.TOPIC.GET_PACK}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: getPackByIdRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TOPIC.GET_PACK("$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
  },
};

export default mapToSchema(schemaShapes);
