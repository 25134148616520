import { SLACK_API } from "@shared/constants/general";

export const NamesOfParentRoutes = {
  AUTH: "/auth*",
  AUTHORIZED: "*",
  WORKSPACE: "/workspace*",
  BILLING: "/billing*",
};

export const NameOfRoutes = {
  SIGN_IN: "/",
  SIGN_UP: "/signup",
  AUTH: "/auth",
  ADD_SLACK_WORKSPACE: "/oauth_workspace",
  INSTALL_SLACK_APPLICATION: "/install-application",
  AUTH_HASH_TOKEN: "/auth-hash-token/:hash",
  WORKSPACE: "/workspace",
  BILLING: "/billing",
  HELP: "/help",
  TOPICS: "/topics",
};

export const NameOfChildRoutes = {
  CHANNEL: {
    LIST: "/",
    CHANNEL_CREATE: "/channels/new",
    CHANNEL_EDIT: "/channels/:id/edit",
  },
  TOPIC: {
    LIST: "/:channel_id",
    HISTORY: "/history/:channel_id",
  },
  BILLING: {
    PORTAL: "/",
    PAYMENT: "/payment/:status",
  },
};

export const ExternalLinks = {
  SLACK_OAUTH: `https://slack.com/openid/connect/authorize?response_type=code&scope=openid%2Cemail%2Cprofile&client_id=${SLACK_API.CLIENT_ID}&redirect_uri=`,
  SLACK_ADD_WORKSPACE: `https://slack.com/oauth/v2/authorize?client_id=${SLACK_API.CLIENT_ID}&scope=channels:join,channels:manage,channels:read,chat:write,users:read,users:read.email,channels:history,groups:history,groups:read&user_scope=&redirect_uri=`,
};
