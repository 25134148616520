import { call, put, takeLatest } from "redux-saga/effects";
import { ActionWithPayload } from "@shared/interfaces";
import { actions } from "@shared/store";
import { BillingPortal } from "@containers/Billing/interface";

import { getBillingPortalUrl } from "./actions";
import api from "../api";

function* getBillingPortalUrlSaga({ payload }: ActionWithPayload<BillingPortal>) {
  try {
    yield put(actions.startLoading());
    const response: { url: string } = yield call(api.getBillingPortalUrl, payload);
    yield put(getBillingPortalUrl.success(response));
    yield put(actions.stopLoading());
  } catch (error) {
    yield put(getBillingPortalUrl.failure(error as Error));
    yield put(actions.stopLoading());
  }
}

function* billingSagas() {
  yield takeLatest(getBillingPortalUrl.request, getBillingPortalUrlSaga);
}

export default billingSagas;
