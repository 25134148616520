import React, { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import { InputFile, Icon } from "@shared/components";
import { actions } from "@shared/store";

import "./index.scss";

export interface FileUploadInputProps {
  onChange: (data: { base64: string; name: string }) => void;
  onDelete: () => void;
  className?: string;
  labelText?: string;
  value?: string | null;
  maxFileSize?: number;
  acceptTypes?: string;
}

export default function FileUploadInput(props: FileUploadInputProps) {
  const { className, onChange, onDelete, value, maxFileSize, acceptTypes } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const handleEdit = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  const displayFileRejectMessage = useCallback(
    (message: string) => {
      dispatch(
        actions.showNotification({
          message,
          appearance: "error",
        }),
      );
    },
    [dispatch],
  );

  return (
    <div className={classnames("file-upload-input-wrapper", className, { remove: value })}>
      <div className="file-upload-input-create-block" onClick={value ? onDelete : handleEdit}>
        <div className="file-upload-input-create-label">
          <Icon type={value ? "file-gif-red" : "file-gif"} />
        </div>
        <div className="file-upload-input-create-label">{value ? "Remove a GIF or IMG" : "Add a GIF or IMG"}</div>
      </div>

      <InputFile
        maxFileSize={maxFileSize ?? 5000000}
        onReject={displayFileRejectMessage}
        accept={acceptTypes ?? "image/png,image/jpg,image/jpeg,image/gif"}
        onChange={onChange}
        inputRef={inputRef}
      />
    </div>
  );
}
