import { AnyAction, combineReducers } from "redux";
import { History } from "history";
import { connectRouter } from "connected-react-router";
import { AppState } from "@shared/interfaces";
import { ChannelReducer } from "@containers/Channel/store/reducer";
import { TopicReducer } from "@containers/Topic/store/reducer";
import { BillingReducer } from "@containers/Billing/store/reducer";

import { AuthReducer } from "../containers/Auth/store/reducer";
import { SharedReducer } from "../shared/store/reducer";

export default (history: History) => {
  const appReducer = combineReducers({
    auth: AuthReducer,
    shared: SharedReducer,
    channel: ChannelReducer,
    topic: TopicReducer,
    billing: BillingReducer,
    router: connectRouter(history),
  });

  return (state: AppState | undefined, action: AnyAction) => {
    return appReducer(state, action);
  };
};
