import React, { FC } from "react";
import { ExternalLinks } from "@shared/constants";
import "./index.scss";

const LoginLink: FC = () => {
  const redirectLink = window.location.href;
  const link = `${ExternalLinks.SLACK_OAUTH}${redirectLink}`;

  return (
    <section className="login-container">
      <a href={link}>
        <span className="slack-svg" />
        Sign in with Slack
      </a>
    </section>
  );
};

export default LoginLink;
