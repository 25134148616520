import React, { FC } from "react";
import classnames from "classnames";
import "./index.scss";
import { IconType } from "@shared/components/Icon/Icon";
import { Icon, RedirectLink } from "@shared/components";

export interface ItemProps {
  active?: boolean;
  name: string;
  path: string;
  iconName: IconType;
  iconNameActive: IconType;
}

const Item: FC<ItemProps> = (props) => {
  const { active, name, path, iconName, iconNameActive } = props;
  return (
    <RedirectLink to={path}>
      <div className={classnames("sidebar-menu-item", { active })}>
        <Icon type={active ? iconNameActive : iconName} className="sidebar-icon" />
        <span>{name}</span>
      </div>
    </RedirectLink>
  );
};

export default Item;
