export enum TopicActionTypes {
  GET_TOPICS = "@@CHANNEL/GET_TOPICS",
  GET_TOPICS_SUCCESS = "@@CHANNEL/GET_TOPICS_SUCCESS",
  GET_TOPICS_FAILURE = "@@CHANNEL/GET_TOPICS_FAILURE",

  GET_TOPICS_HISTORY = "@@CHANNEL/GET_TOPICS_HISTORY",
  GET_TOPICS_HISTORY_SUCCESS = "@@CHANNEL/GET_TOPICS_HISTORY_SUCCESS",
  GET_TOPICS_HISTORY_FAILURE = "@@CHANNEL/GET_TOPICS_HISTORY_FAILURE",

  GET_PACKS = "@@CHANNEL/GET_PACKS",
  GET_PACKS_SUCCESS = "@@CHANNEL/GET_PACKS_SUCCESS",
  GET_PACKS_FAILURE = "@@CHANNEL/GET_PACKS_FAILURE",

  GET_PACK = "@@CHANNEL/GET_PACK",
  GET_PACK_SUCCESS = "@@CHANNEL/GET_PACK_SUCCESS",
  GET_PACK_FAILURE = "@@CHANNEL/GET_PACK_FAILURE",

  GET_PACKS_CHANNEL = "@@CHANNEL/GET_PACKS_CHANNEL",
  GET_PACKS_CHANNEL_SUCCESS = "@@CHANNEL/GET_PACKS_CHANNEL_SUCCESS",
  GET_PACKS_CHANNEL_FAILURE = "@@CHANNEL/GET_PACKS_CHANNEL_FAILURE",

  CREATE_PACK_CHANNEL = "@@CHANNEL/CREATE_PACK_CHANNEL",
  CREATE_PACK_CHANNEL_SUCCESS = "@@CHANNEL/CREATE_PACK_CHANNEL_SUCCESS",
  CREATE_PACK_CHANNEL_FAILURE = "@@CHANNEL/CREATE_PACK_CHANNEL_FAILURE",

  DELETE_PACK_CHANNEL = "@@CHANNEL/DELETE_PACK_CHANNEL",
  DELETE_PACK_CHANNEL_SUCCESS = "@@CHANNEL/DELETE_PACK_CHANNEL_SUCCESS",
  DELETE_PACK_CHANNEL_FAILURE = "@@CHANNEL/DELETE_PACK_CHANNEL_FAILURE",

  DELETE_TOPIC_CHANNEL = "@@CHANNEL/DELETE_TOPIC_CHANNEL",
  DELETE_TOPIC_CHANNEL_SUCCESS = "@@CHANNEL/DELETE_TOPIC_CHANNEL_SUCCESS",
  DELETE_TOPIC_CHANNEL_FAILURE = "@@CHANNEL/DELETE_TOPIC_CHANNEL_FAILURE",

  UPDATE_QUEUED_TOPIC_ACTIVE = "@@CHANNEL/UPDATE_QUEUED_TOPIC_ACTIVE",
  UPDATE_QUEUED_TOPIC_ACTIVE_SUCCESS = "@@CHANNEL/UPDATE_QUEUED_TOPIC_ACTIVE_SUCCESS",
  UPDATE_QUEUED_TOPIC_ACTIVE_FAILURE = "@@CHANNEL/UPDATE_QUEUED_TOPIC_ACTIVE_FAILURE",

  UPDATE_QUEUED_TOPIC_ORDER = "@@CHANNEL/UPDATE_QUEUED_TOPIC_ORDER",
  UPDATE_QUEUED_TOPIC_ORDER_SUCCESS = "@@CHANNEL/UPDATE_QUEUED_TOPIC_ORDER_SUCCESS",
  UPDATE_QUEUED_TOPIC_ORDER_FAILURE = "@@CHANNEL/UPDATE_QUEUED_TOPIC_ORDER_FAILURE",

  SHUFFLE_TOPICS = "@@CHANNEL/SHUFFLE_TOPICS",
  SHUFFLE_TOPICS_SUCCESS = "@@CHANNEL/SHUFFLE_TOPICS_SUCCESS",
  SHUFFLE_TOPICS_FAILURE = "@@CHANNEL/SHUFFLE_TOPICS_FAILURE",

  CREATE_TOPIC = "@@CHANNEL/CREATE_TOPIC",
  CREATE_TOPIC_SUCCESS = "@@CHANNEL/CREATE_TOPIC_SUCCESS",
  CREATE_TOPIC_FAILURE = "@@CHANNEL/CREATE_TOPIC_FAILURE",

  GET_TOPIC = "@@CHANNEL/GET_TOPIC",
  GET_TOPIC_SUCCESS = "@@CHANNEL/GET_TOPIC_SUCCESS",
  GET_TOPIC_FAILURE = "@@CHANNEL/GET_TOPIC_FAILURE",

  UPDATE_TOPIC = "@@CHANNEL/UPDATE_TOPIC",
  UPDATE_TOPIC_SUCCESS = "@@CHANNEL/UPDATE_TOPIC_SUCCESS",
  UPDATE_TOPIC_FAILURE = "@@CHANNEL/UPDATE_TOPIC_FAILURE",

  CLEAR_TOPICS = "@@CHANNEL/CLEAR_TOPICS",
}
