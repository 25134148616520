import { createAction, createAsyncAction } from "typesafe-actions";
import { ActionWithId, PayloadWithId } from "@shared/interfaces";
import { TopicActionTypes } from "@containers/Topic/store/constants";
import { Topic, TopicQueue } from "@shared/models";
import { Pack, PackDetailed } from "@shared/models/Pack";
import {
  CreatePackChannelShape,
  DeletePackChannelShape,
  DeleteTopicChannelShape,
  TopicFormShapeToRequest,
  TopicQueueOrderShape,
  UpdateQueuedTopicActiveShape,
  UpdateTopicSuccess,
} from "@containers/Topic/interface";
import { BaseResponse } from "@shared/interfaces/BaseResponse.interface";

export const getTopics = createAsyncAction(
  TopicActionTypes.GET_TOPICS,
  TopicActionTypes.GET_TOPICS_SUCCESS,
  TopicActionTypes.GET_TOPICS_FAILURE,
)<ActionWithId, TopicQueue[], Error>();

export const getTopicsHistory = createAsyncAction(
  TopicActionTypes.GET_TOPICS_HISTORY,
  TopicActionTypes.GET_TOPICS_HISTORY_SUCCESS,
  TopicActionTypes.GET_TOPICS_HISTORY_FAILURE,
)<ActionWithId, TopicQueue[], Error>();

export const shuffleTopics = createAsyncAction(
  TopicActionTypes.SHUFFLE_TOPICS,
  TopicActionTypes.SHUFFLE_TOPICS_SUCCESS,
  TopicActionTypes.SHUFFLE_TOPICS_FAILURE,
)<ActionWithId, TopicQueue[], Error>();

export const getPacks = createAsyncAction(
  TopicActionTypes.GET_PACKS,
  TopicActionTypes.GET_PACKS_SUCCESS,
  TopicActionTypes.GET_PACKS_FAILURE,
)<null, Pack[], Error>();

export const getPack = createAsyncAction(
  TopicActionTypes.GET_PACK,
  TopicActionTypes.GET_PACK_SUCCESS,
  TopicActionTypes.GET_PACK_FAILURE,
)<ActionWithId, PackDetailed | null, Error>();

export const getPacksChannel = createAsyncAction(
  TopicActionTypes.GET_PACKS_CHANNEL,
  TopicActionTypes.GET_PACKS_CHANNEL_SUCCESS,
  TopicActionTypes.GET_PACKS_CHANNEL_FAILURE,
)<ActionWithId, Pack[], Error>();

export const createPackChannel = createAsyncAction(
  TopicActionTypes.CREATE_PACK_CHANNEL,
  TopicActionTypes.CREATE_PACK_CHANNEL_SUCCESS,
  TopicActionTypes.CREATE_PACK_CHANNEL_FAILURE,
)<CreatePackChannelShape, { response: BaseResponse; pack_id: string | number }, Error>();

export const deletePackChannel = createAsyncAction(
  TopicActionTypes.DELETE_PACK_CHANNEL,
  TopicActionTypes.DELETE_PACK_CHANNEL_SUCCESS,
  TopicActionTypes.DELETE_PACK_CHANNEL_FAILURE,
)<DeletePackChannelShape, { response: BaseResponse; pack_id: string | number }, Error>();

export const deleteTopicChannel = createAsyncAction(
  TopicActionTypes.DELETE_TOPIC_CHANNEL,
  TopicActionTypes.DELETE_TOPIC_CHANNEL_SUCCESS,
  TopicActionTypes.DELETE_TOPIC_CHANNEL_FAILURE,
)<DeleteTopicChannelShape, BaseResponse, Error>();

export const updateQueuedTopicActive = createAsyncAction(
  TopicActionTypes.UPDATE_QUEUED_TOPIC_ACTIVE,
  TopicActionTypes.UPDATE_QUEUED_TOPIC_ACTIVE_SUCCESS,
  TopicActionTypes.UPDATE_QUEUED_TOPIC_ACTIVE_FAILURE,
)<Required<PayloadWithId<UpdateQueuedTopicActiveShape>>, TopicQueue[], Error>();

export const updateQueuedTopicOrder = createAsyncAction(
  TopicActionTypes.UPDATE_QUEUED_TOPIC_ORDER,
  TopicActionTypes.UPDATE_QUEUED_TOPIC_ORDER_SUCCESS,
  TopicActionTypes.UPDATE_QUEUED_TOPIC_ORDER_FAILURE,
)<PayloadWithId<TopicQueueOrderShape[]>, BaseResponse, Error>();

export const createTopic = createAsyncAction(
  TopicActionTypes.CREATE_TOPIC,
  TopicActionTypes.CREATE_TOPIC_SUCCESS,
  TopicActionTypes.CREATE_TOPIC_FAILURE,
)<TopicFormShapeToRequest, BaseResponse, Error>();

export const getTopic = createAsyncAction(
  TopicActionTypes.GET_TOPIC,
  TopicActionTypes.GET_TOPIC_SUCCESS,
  TopicActionTypes.GET_TOPIC_FAILURE,
)<ActionWithId, Topic | null, Error>();

export const updateTopic = createAsyncAction(
  TopicActionTypes.UPDATE_TOPIC,
  TopicActionTypes.UPDATE_TOPIC_SUCCESS,
  TopicActionTypes.UPDATE_TOPIC_FAILURE,
)<Required<PayloadWithId<TopicFormShapeToRequest>>, UpdateTopicSuccess, Error>();

export const clearTopics = createAction(TopicActionTypes.CLEAR_TOPICS)();
