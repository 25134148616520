import { ActionWithId, PayloadWithId } from "@shared/interfaces";
import {
  CreatePackChannelShape,
  DeletePackChannelShape,
  DeleteTopicChannelShape,
  TopicFormShapeToRequest,
  TopicQueueOrderShape,
  UpdateQueuedTopicActiveShape,
} from "@containers/Topic/interface";

import { request } from "../../../shared/utils";
import { API, METHODS } from "../../../shared/constants";

export default {
  getTopics: (payload: Required<ActionWithId>) => {
    return request(METHODS.GET, API.TOPIC.GET_TOPICS(payload.id))();
  },
  getTopicsHistory: (payload: Required<ActionWithId>) => {
    return request(METHODS.GET, API.TOPIC.GET_TOPICS_HISTORY(payload.id))();
  },
  getPacks: () => request(METHODS.GET, API.TOPIC.GET_PACKS)(),
  getPack: (payload: Required<ActionWithId>) => {
    return request(METHODS.GET, API.TOPIC.GET_PACK(payload.id))();
  },
  getPacksForChannel: (payload: Required<ActionWithId>) => {
    return request(METHODS.GET, API.TOPIC.GET_PACKS_CHANNEL(payload.id))();
  },
  shuffleTopics: (payload: Required<ActionWithId>) => {
    return request(METHODS.PUT, API.TOPIC.SHUFFLE_TOPICS(payload.id))();
  },
  subscribeChannelForPack: (payload: CreatePackChannelShape) => {
    return request(METHODS.PUT, API.TOPIC.SUBSCRIBE_CHANNEL_PACK(payload.channel_id, payload.pack_id))();
  },
  unsubscribeChannelFromPack: (payload: DeletePackChannelShape) => {
    return request(METHODS.DELETE, API.TOPIC.UNSUBSCRIBE_CHANNEL_PACK(payload.channel_id, payload.pack_id))();
  },
  deleteTopicFromChannel: (payload: DeleteTopicChannelShape) => {
    return request(METHODS.DELETE, API.TOPIC.DELETE_TOPIC_CHANNEL(payload.channel_id, payload.queued_topic_id))();
  },
  updateQueuedTopicActive: (payload: Required<PayloadWithId<UpdateQueuedTopicActiveShape>>) => {
    return request(METHODS.PUT, API.TOPIC.UPDATE_QUEUED_TOPIC_ACTIVE(payload.id))(payload.payload);
  },
  updateQueuedTopicOrder: (payload: PayloadWithId<TopicQueueOrderShape[]>) => {
    return request(METHODS.PUT, API.TOPIC.UPDATE_QUEUED_TOPIC_ORDER(payload.id))(payload.payload);
  },
  createTopic: (payload: TopicFormShapeToRequest) => request(METHODS.POST, API.TOPIC.CREATE_TOPIC)(payload),
  getTopic: (payload: Required<ActionWithId>) => {
    return request(METHODS.GET, API.TOPIC.GET_TOPIC(payload.id))();
  },
  updateTopic: (payload: PayloadWithId<TopicFormShapeToRequest>) => {
    return request(METHODS.PUT, API.TOPIC.UPDATE_TOPIC(payload.id))(payload.payload);
  },
};
