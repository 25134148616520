import React from "react";
import styled, { css } from "styled-components";

export type ButtonType = "button" | "submit";
export type ButtonStyle = "accent" | "bordered" | "draft";
export type ButtonColor = "blue" | "red" | "green" | "default";

interface ButtonProps {
  type?: ButtonType;
  buttonStyle?: ButtonStyle;
  buttonColor?: ButtonColor;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const accentStyle = css`
  background: radial-gradient(34.47% 150.88% at 25.57% 14.91%, #f8af4b 0%, #eaa13d 100%);
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.08), 0px 4px 8px rgba(44, 39, 56, 0.08);
  border-radius: 12px;
  color: #ffffff;
  font-weight: 600;
`;

const borderedStyle = css`
  background: rgba(243, 170, 70, 0.03);
  border: 2px solid #f3aa46;
  box-sizing: border-box;
  border-radius: 12px;
  color: #f3aa46;
  margin: 0px 8px;
  line-height: 21px;
`;

const draftStyle = css`
  background: rgba(243, 170, 70, 0.1);
  box-sizing: border-box;
  border-radius: 12px;
  color: #f3aa46;
  margin: 0px 8px;
  line-height: 21px;
`;

const disabledStyle = css`
  background-color: #9e9e9e;
  color: #fff;
`;

const defaultColor = css`
  color: #ffffff;
`;

const blueColor = css`
  background: rgba(157, 175, 212, 0.1);
  border-color: #9dafd4;
  color: #9dafd4;
`;

const redColor = css`
  background: #ed7575;
  color: #fff;
`;

const greenColor = css`
  border-color: #14a38b;
  color: #14a38b;
`;

function getButtonStyle(props: ButtonProps) {
  if (props.disabled) {
    return disabledStyle;
  }
  switch (props.buttonStyle) {
    case "bordered":
      return borderedStyle;
    case "draft":
      return draftStyle;
    default:
      return accentStyle;
  }
}

function getButtonColor(props: ButtonProps) {
  switch (props.buttonColor) {
    case "blue":
      return blueColor;
    case "red":
      return redColor;
    case "green":
      return greenColor;
    case "default":
      return defaultColor;
  }
}

const ButtonComponent = styled.button`
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 45px;
  height: 45px;
  margin-left: 20px;
  padding: 0 13px;
  box-sizing: border-box;
  border-radius: 12px;

  .icon {
    width: 18px;
    height: 18px;
    background-size: contain;
  }

  ${(props: ButtonProps) => getButtonStyle(props)}
  ${(props: ButtonProps) => getButtonColor(props)}
`;

const Button: React.FC<ButtonProps> = (props) => {
  return <ButtonComponent {...props}>{props.children}</ButtonComponent>;
};

export default Button;
