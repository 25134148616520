import { EXTERNAL_SYSTEMS } from "@shared/constants";

export const iconPositionConfig = {
  default: { bottom: "10px", right: "10px", "z-index": "1" },
};

let widget: HTMLElement | null = null;

const getWidget = async () =>
  new Promise<HTMLElement | null>((resolve) => {
    const INTERVAL = 300;
    let COUNT_OF_CHECKS = 50;

    const intervalId = setInterval(() => {
      const widgetElement = document.getElementById("hubspot-messages-iframe-container");
      if (widgetElement || !COUNT_OF_CHECKS--) {
        clearTimeout(intervalId);
        return resolve(widgetElement);
      }
    }, INTERVAL);
  });

export const initHubspotChat = async () => {
  if (EXTERNAL_SYSTEMS.HUBSPOT_CHAT_URL) {
    const hubspotScript = document.createElement("script");
    hubspotScript.type = "text/javascript";
    hubspotScript.src = EXTERNAL_SYSTEMS.HUBSPOT_CHAT_URL;
    document.head.append(hubspotScript);
    widget = await getWidget();
    applyStyles(iconPositionConfig.default);
  }
};

const applyStyles = (styles: { [prop: string]: string }): void => {
  if (widget) {
    Object.entries(styles).forEach(([prop, val]) => {
      (widget as HTMLElement).style.setProperty(prop, val, "important");
    });
  }
};
