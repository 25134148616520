import React from "react";

import "./index.scss";

interface NoMatchesFoundProps {
  label?: string;
}

const NoMatchesFound = (props: NoMatchesFoundProps) => {
  const { label = "No matches found" } = props;
  return (
    <div className="no-matches-found">
      <div className="no-matches-label">{label}</div>
    </div>
  );
};

export default NoMatchesFound;
