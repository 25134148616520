import React, { useEffect } from "react";
import { generatePath } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import useQuery from "@shared/hooks/QueryHook";
import { LoginLink } from "@containers/Auth/components/LoginContainer/LoginLink";
import { actions, selectors } from "@containers/Auth/store";
import { selectors as sharedSelectors, actions as sharedActions } from "@shared/store";
import { LoadingSpinner } from "@shared/components";
import { NameOfRoutes } from "@shared/constants";
import "./index.scss";

const LoginContainer = () => {
  const dispatch = useDispatch();
  const authentificated = useSelector(selectors.authentificated());
  const user = useSelector(sharedSelectors.getUser());

  const query = useQuery();
  const code = query.get("code");

  useEffect(() => {
    if (code) {
      dispatch(actions.login.request({ code }));
    }
  }, [dispatch, code]);

  useEffect(() => {
    if (authentificated) {
      dispatch(sharedActions.getUser.request());
    }
  }, [authentificated, dispatch]);

  useEffect(() => {
    if (user) {
      user.stripe_customer_code
        ? dispatch(sharedActions.navigate(generatePath(NameOfRoutes.WORKSPACE)))
        : dispatch(sharedActions.navigate(generatePath(NameOfRoutes.INSTALL_SLACK_APPLICATION)));
    }
  }, [dispatch, user]);

  return <section className="login-container">{code ? <LoadingSpinner /> : <LoginLink />}</section>;
};

export default LoginContainer;
