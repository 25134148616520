import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import { AuthStateType } from "../interface";
import { tokenHandler } from "../../../shared/utils";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

const initialState: AuthStateType = {
  authentificated: !!tokenHandler.get(),
};

const reducer = createReducer<AuthStateType, Action>(initialState)
  .handleAction(actions.login.success, (state) =>
    produce(state, (nextState) => {
      nextState.authentificated = true;
    }),
  )
  .handleAction(actions.addSlackWorkspace.success, (state) =>
    produce(state, (nextState) => {
      nextState.authentificated = true;
    }),
  )
  .handleAction(actions.authHashToken.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.authentificated = !!action.payload;
    }),
  )
  .handleAction(actions.logout.success, (state) =>
    produce(state, (nextState) => {
      nextState.authentificated = false;
    }),
  );

export { reducer as AuthReducer };
