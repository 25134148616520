import React, { FC, useEffect, useState } from "react";
import { convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { stateFromHTML } from "draft-js-import-html";
import classnames from "classnames";
import "./index.scss";

interface TextareaProps extends React.HTMLProps<HTMLTextAreaElement> {
  value: string;
  className?: string;
  label?: string;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue?: (name: any, option: any) => void;
  isTouched?: boolean;
}

const RichTextArea: FC<TextareaProps> = (props) => {
  const { className, setFieldValue, value, label, disabled, isTouched } = props;

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (!isChanged && value) {
      setEditorState(EditorState.createWithContent(stateFromHTML(value)));
    }
  }, [value, isChanged]);

  return (
    <div className={classnames("rich-textarea-wrapper", className)}>
      <div>
        <label className={classnames({ disabled })}>{label}</label>
      </div>
      <Editor
        editorState={editorState}
        toolbarClassName="rich-textarea-toolbar"
        wrapperClassName="rich-textarea"
        editorClassName="rich-textarea-editor"
        onChange={() => {
          !isChanged && setIsChanged(true);

          const content = draftToHtml(convertToRaw(editorState.getCurrentContent()))
            .replace("<p></p>", "")
            .replaceAll("&nbsp;", "");
          isTouched
            ? setFieldValue && setFieldValue(props.name, content)
            : content
            ? setFieldValue && setFieldValue(props.name, content)
            : null;
        }}
        onEditorStateChange={(v) => {
          setEditorState(v);
        }}
        toolbar={{
          options: ["inline", "emoji", "link", "list"],
          inline: {
            options: ["bold", "italic", "strikethrough", "monospace"],
          },
          link: {
            options: ["link"],
          },
          list: {
            options: ["unordered"],
          },
        }}
      />
    </div>
  );
};
export default RichTextArea;
