import React from "react";
import classnames from "classnames";

export type IconType =
  | "notification-error"
  | "notification-success"
  | "back"
  | "back-long"
  | "find"
  | "clear"
  | "edit"
  | "back-gray"
  | "close"
  | "edit-white"
  | "delete-white"
  | "back-white"
  | "front-orange"
  | "lock"
  | "filter"
  | "billing"
  | "billing-active"
  | "workspace"
  | "workspace-active"
  | "help"
  | "help-active"
  | "option-selected-green"
  | "document"
  | "repeat"
  | "settings"
  | "play"
  | "slack"
  | "gif"
  | "delete"
  | "shuffle"
  | "logout"
  | "file-gif"
  | "file-gif-red"
  | "file-gif-2"
  | "history"
  | "schedule"
  | "pack-default"
  | "message"
  | "eye"
  | "spinner"
  | "spinner-space"
  | "looking"
  | "logo"
  | "channels"
  | "switch-on"
  | "switch-off"
  | "refresh";

export interface IconProps {
  type: IconType;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
}

export default function Icon(props: IconProps) {
  const { type, className, onClick } = props;
  return <div onClick={onClick} className={classnames("icon", className, type)} />;
}
