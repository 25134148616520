import { createAction, createAsyncAction } from "typesafe-actions";
import { Channel, ChannelFormShapeToRequest, SlackChannel, SlackUser } from "@shared/models";
import { ChannelActionTypes } from "@containers/Channel/store/constants";
import { ActionWithId, PayloadWithId } from "@shared/interfaces";
import { BaseResponse } from "@shared/interfaces/BaseResponse.interface";

export const getChannels = createAsyncAction(
  ChannelActionTypes.GET_CHANNELS,
  ChannelActionTypes.GET_CHANNELS_SUCCESS,
  ChannelActionTypes.GET_CHANNELS_FAILURE,
)<{ workspace_id: number }, Channel[], Error>();

export const getChannel = createAsyncAction(
  ChannelActionTypes.GET_CHANNEL,
  ChannelActionTypes.GET_CHANNEL_SUCCESS,
  ChannelActionTypes.GET_CHANNEL_FAILURE,
)<ActionWithId, Channel | null, Error>();

export const findSlackChannels = createAsyncAction(
  ChannelActionTypes.FIND_SLACK_CHANNELS,
  ChannelActionTypes.FIND_SLACK_CHANNELS_SUCCESS,
  ChannelActionTypes.FIND_SLACK_CHANNELS_FAILURE,
)<{ search?: string }, SlackChannel[], Error>();

export const findSlackUsers = createAsyncAction(
  ChannelActionTypes.FIND_SLACK_USERS,
  ChannelActionTypes.FIND_SLACK_USERS_SUCCESS,
  ChannelActionTypes.FIND_SLACK_USERS_FAILURE,
)<{ search?: string }, SlackUser[], Error>();

export const createChannel = createAsyncAction(
  ChannelActionTypes.CREATE_CHANNEL,
  ChannelActionTypes.CREATE_CHANNEL_SUCCESS,
  ChannelActionTypes.CREATE_CHANNEL_FAILURE,
)<ChannelFormShapeToRequest, BaseResponse, Error>();

export const updateChannel = createAsyncAction(
  ChannelActionTypes.UPDATE_CHANNEL,
  ChannelActionTypes.UPDATE_CHANNEL_SUCCESS,
  ChannelActionTypes.UPDATE_CHANNEL_FAILURE,
)<Required<PayloadWithId<ChannelFormShapeToRequest>>, Channel, Error>();

export const deleteChannel = createAsyncAction(
  ChannelActionTypes.DELETE_CHANNEL,
  ChannelActionTypes.DELETE_CHANNEL_SUCCESS,
  ChannelActionTypes.DELETE_CHANNEL_FAILURE,
)<Required<ActionWithId>, BaseResponse, Error>();

export const clearChannels = createAction(ChannelActionTypes.CLEAR_CHANNELS)();
