import React from "react";
import "./index.scss";
import { Button } from "@shared/components";

export interface LockAreaProps {
  title: string;
  onClick: () => void;
}

export default function LockArea(props: LockAreaProps) {
  const { title, onClick } = props;

  return (
    <div className="lock-wrapper">
      <div className="lock" />
      <div className="subscribe">
        <Button type="button" buttonStyle="accent" buttonColor="red" className="add-button" onClick={onClick}>
          {title}
        </Button>
      </div>
    </div>
  );
}
