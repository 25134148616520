import Cookies from "universal-cookie";
import { AnyType } from "@shared/interfaces";

export const debounce = <F extends (...args: AnyType) => AnyType>(func: F, waitFor = 500) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  const debounced = (...args: AnyType) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export const getFormattedTime = (publish_time: string | Date) => {
  return new Date(publish_time).toLocaleString("ru", {
    hour: "2-digit",
    // minute: "2-digit",
    // second: "2-digit",
  });
};

export function getUniqueArray<T>(a: T[], property: keyof T) {
  return a.filter((item, pos, self) => self.findIndex((v) => v[property] === item[property]) === pos);
}

export function getCutString(str: string, maxLength = 103, dotted = true) {
  return str.length > maxLength
    ? dotted
      ? str.substring(0, maxLength - 3) + "..."
      : str.substring(0, maxLength)
    : str;
}

export const htmlToText = (html: string): string => html.replace(/<[^>]+>/g, "");

export const is24Format = new Date(2022, 1, 1, 15, 0, 0, 0).toLocaleTimeString().indexOf("15") > -1;

export const setAppCookie = (name: string, value: string, time: number = 4 * 60 * 60 * 1000) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + time);

  const cookies = new Cookies();
  cookies.set(name, value, { path: "/", expires });
};
