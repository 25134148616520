import { API_ENUM } from "@shared/constants/api";

import { API, METHODS, SCHEMAS } from "../../../shared/constants";

export const commonLoginSchema = {
  type: "object",
  required: ["token"],
  properties: {
    token: { type: "string" },
  },
  additionalProperties: false,
};

export default {
  [`${API.AUTH.LOGIN}${SCHEMAS.REQUEST}${METHODS.POST}`]: {
    type: "object",
    required: ["code"],
    properties: {
      code: { type: "string" },
    },
    additionalProperties: false,
  },
  [`${API.AUTH.LOGIN}${SCHEMAS.RESPONSE}${METHODS.POST}`]: commonLoginSchema,
  [API_ENUM.ADD_SLACK_WORKSPACE]: {
    request: {
      name: `${API.WORKSPACE.ADD_SLACK_WORKSPACE}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: {},
    },
    response: {
      name: `${API.WORKSPACE.ADD_SLACK_WORKSPACE}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: {},
    },
  },
};
