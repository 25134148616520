import { createSelector } from "reselect";

import { AppState } from "../interfaces";

const selectShared = (state: AppState) => state.shared;

export const getLoading = () => createSelector(selectShared, (state) => state.loading);

export const getNotification = () => createSelector(selectShared, (state) => state.notification);

export const getNavigateURL = () => createSelector(selectShared, (state) => state.navigateURL);

export const getUser = () => createSelector(selectShared, (state) => state.user);
export const getWorkspace = () => createSelector(selectShared, (state) => state.workspace);
export const getStripeUrl = () => createSelector(selectShared, (state) => state.stripeURL);
export const getStripeDiscountUrl = () => createSelector(selectShared, (state) => state.stripeDiscountURL);

export const selectRouter = () =>
  createSelector(
    (state: AppState) => state,
    (state) => state,
  );

export const getModal = () => createSelector(selectShared, (state) => state.modal);
export const getOverlay = () => createSelector(selectShared, (state) => state.overlay);
export const getLoadingSections = () => createSelector(selectShared, (state) => Object.keys(state.loadingSections));
export const getLoadingSection = (sectionName: string) =>
  createSelector(selectShared, (state) => state.loadingSections[sectionName]);
