import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import classnames from "classnames";

import { TableProperty } from "./interface";
import { Head } from "./Head";
import { Row } from "./Row";
import { DragDropRow, DragDropRowDisabled } from "./DragDropRow";
import "./index.scss";

export interface TableProps<T> {
  items: T[];
  properties: TableProperty<T>[];
  onRowClick?: (item: T) => void;
  isDragAndDrop?: boolean;
  moveCard?: (dragIndex: number, hoverIndex: number) => void;
  onDragAndDropRequest?: () => void;
}

function Table<T extends { id?: number | string; is_active?: boolean }>(props: TableProps<T>) {
  const { items, properties, onRowClick, isDragAndDrop, moveCard, onDragAndDropRequest } = props;

  return (
    <div className="table-wrapper">
      <Head<T> properties={properties} />
      {isDragAndDrop && moveCard ? (
        <DndProvider backend={HTML5Backend}>
          <div className="table-dnd-content">
            {items.map((item, index) =>
              item.is_active ? (
                <DragDropRow<T>
                  key={item.id}
                  id={item.id}
                  item={item}
                  properties={properties}
                  index={index}
                  moveCard={moveCard}
                  onDragAndDropRequest={onDragAndDropRequest}
                />
              ) : (
                <DragDropRowDisabled<T> key={item.id} item={item} properties={properties} />
              ),
            )}
          </div>
        </DndProvider>
      ) : (
        <div className={classnames("table-content", { "table-clickable": onRowClick })}>
          {items.map((item, index) => (
            <Row<T> onClick={() => onRowClick && onRowClick(item)} key={index} item={item} properties={properties} />
          ))}
        </div>
      )}
    </div>
  );
}

export default Table;
