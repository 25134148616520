import React, { FC } from "react";
import { Icon } from "@shared/components";

import "./indes.scss";

interface BackButtonProps {
  onClick?: () => void;
  withText?: boolean;
}

const BackButton: FC<BackButtonProps> = (props) => {
  const { onClick, withText } = props;

  return (
    <div className="back-button" onClick={onClick}>
      <Icon type="back-long" />
      {withText ? <div>Back</div> : null}
    </div>
  );
};

export default BackButton;
