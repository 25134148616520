import React from "react";
import "./index.scss";
import { Button, Icon } from "@shared/components";
import { IconType } from "@shared/components/Icon/Icon";
import { ButtonColor } from "@shared/components/Common/Button/Button";
import { STRIPE_DISCOUNT_LABEL } from "@shared/constants";

interface NoMatchesFoundProps {
  title?: string;
  subtitle?: string;
  buttonLabel?: string;
  buttonColor?: ButtonColor;
  iconType?: IconType;
  onClick?: () => void;
  onPayDiscountRedirect?: () => void;
  isStripeDiscount?: string | null;
}

const NoMatchesFoundWithButton = (props: NoMatchesFoundProps) => {
  const {
    title = "No matches found",
    subtitle,
    buttonLabel,
    onClick,
    iconType = "channels",
    buttonColor = "default",
    onPayDiscountRedirect,
    isStripeDiscount,
  } = props;
  return (
    <div className="no-matches-found-button-wrapper">
      <div className="no-matches-found-button">
        <Icon type={iconType} />
        <div className="title">{title}</div>
        {subtitle && <div className="subtitle">{subtitle}</div>}
        {buttonLabel && onClick && (
          <Button type="button" buttonStyle="accent" buttonColor={buttonColor} className="add-button" onClick={onClick}>
            {buttonLabel}
          </Button>
        )}
        {isStripeDiscount && onPayDiscountRedirect && (
          <Button type="button" buttonStyle="accent" className="add-button" onClick={onPayDiscountRedirect}>
            {STRIPE_DISCOUNT_LABEL}
          </Button>
        )}
      </div>
    </div>
  );
};

export default NoMatchesFoundWithButton;
