import Ajv from "ajv";
import authSchema from "@containers/Auth/api/schemas";
//import channelSchema from "@containers/Channel/api/schemas";
//import sharedSchema from "@shared/api/schemas";

const ajv = new Ajv({ schemaId: "auto" });

const allSchemas = {
  ...authSchema,
  //...channelSchema,
  // ...sharedSchema,
};

const compiledSchemas = Object.entries(allSchemas).reduce((acc, [key, value]) => {
  acc[key] = ajv.compile(value);
  return acc;
}, {} as { [key: string]: Ajv.ValidateFunction });

export default compiledSchemas;
