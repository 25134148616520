export enum PAYMENT_STATUS {
  SUCCESS = "success",
  CANCEL = "cancel",
}

export enum EXTERNAL_SYSTEMS {
  HUBSPOT_CHAT_URL = "//js.hs-scripts.com/21418907.js",
  SENTRY_DSN = "https://f9bf527988b94e57acc882f4564d4da1@o224005.ingest.sentry.io/6199049",
}

export enum REDIRECT_URLS {
  HELP_PAGE = "https://lunchkinapp.com/support",
}

export enum DEFAULT_COLORS {
  LIGHT_THEME_CUSTOM_PACK = "#F3AA46",
}

export enum SLACK_API {
  CLIENT_ID = "13368079204.2795468599729",
}

export const STRIPE_DISCOUNT_LABEL = "Take your 70% discount!";
