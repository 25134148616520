import React, { FC, lazy, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import { Loadable, LoadingIndicator, LoadingSpinner } from "@shared/components";
import { AuthGuard } from "@shared/guards";
import { selectors as authSelectors, actions as authActions } from "@containers/Auth/store";
import { selectors as sharedSelectors, actions as sharedActions } from "@shared/store";
import { Sidebar } from "@shared/components/Sidebar";
import { NameOfRoutes, NamesOfParentRoutes, REDIRECT_URLS } from "@shared/constants";
import { useShowModal } from "@shared/hooks";

const WorkspaceContainer = Loadable(
  lazy(() => import("@containers/Workspace/containers/WorkspaceContainer/WorkspaceContainer")),
);

const BillingContainer = Loadable(
  lazy(() => import("@containers/Billing/containers/BillingContainer/BillingContainer")),
);

const routes = [
  {
    path: NamesOfParentRoutes.WORKSPACE,
    element: (
      <AuthGuard>
        <WorkspaceContainer />
      </AuthGuard>
    ),
  },
  {
    path: NamesOfParentRoutes.BILLING,
    element: (
      <AuthGuard>
        <BillingContainer />
      </AuthGuard>
    ),
  },
  {
    path: NameOfRoutes.HELP,
    element: (
      <AuthGuard>
        <div className="page-container">
          <LoadingSpinner redirectTo={REDIRECT_URLS.HELP_PAGE} />
        </div>
      </AuthGuard>
    ),
  },
];

const ApplicationContainer: FC = () => {
  const content = useRoutes(routes);
  const dispatch = useDispatch();
  const showModal = useShowModal();

  const user = useSelector(sharedSelectors.getUser());
  const workspace = useSelector(sharedSelectors.getWorkspace());
  const loading = useSelector(sharedSelectors.getLoading());
  const loadingSections = useSelector(sharedSelectors.getLoadingSections());

  const isAuthentificated = useSelector(authSelectors.authentificated());

  const isLoader = useMemo(() => loading || !!loadingSections.length, [loading, loadingSections]);

  useEffect(() => {
    if (!user && isAuthentificated) {
      dispatch(sharedActions.getUser.request());
    }
  }, [dispatch, user, isAuthentificated]);

  useEffect(() => {
    if (!workspace && isAuthentificated) {
      dispatch(sharedActions.getWorkspace.request());
    }
  }, [dispatch, workspace, isAuthentificated]);

  const logout = useCallback(() => {
    showModal({
      title: "Logout",
      body: "Are you sure you want to logout?",
      confirmText: "Logout",
      onConfirm: () => dispatch(authActions.logout.request()),
    });
  }, [dispatch, showModal]);

  return (
    <div className="app-container">
      {isLoader ? <LoadingIndicator /> : null}
      <Sidebar
        items={[
          {
            name: "My Workspace",
            path: NameOfRoutes.WORKSPACE,
            iconName: "workspace",
            iconNameActive: "workspace-active",
          },
          {
            name: "Billing",
            path: NameOfRoutes.BILLING,
            iconName: "billing",
            iconNameActive: "billing-active",
          },
        ]}
        supportItems={[
          {
            name: "Help",
            path: NameOfRoutes.HELP,
            iconName: "help",
            iconNameActive: "help-active",
          },
        ]}
        activePath={location.pathname}
        user={user}
        workspace={workspace}
        onLogout={logout}
      />
      <div className="app-content">{content}</div>
    </div>
  );
};

export default ApplicationContainer;
