import { startLoading, stopLoading } from "@shared/store/actions";
import { call, put, takeLatest } from "redux-saga/effects";
import { Topic, TopicQueue } from "@shared/models";
import { ActionWithPayload, PayloadWithId } from "@shared/interfaces";
import { Pack, PackDetailed } from "@shared/models/Pack";
import { BaseResponse } from "@shared/interfaces/BaseResponse.interface";
import {
  CreatePackChannelShape,
  DeletePackChannelShape,
  DeleteTopicChannelShape,
  TopicFormShapeToRequest,
  TopicQueueOrderShape,
  UpdateQueuedTopicActiveShape,
  UpdateTopicSuccess,
} from "@containers/Topic/interface";

import api from "../api";
import {
  createPackChannel,
  createTopic,
  deletePackChannel,
  deleteTopicChannel,
  getPack,
  getPacks,
  getPacksChannel,
  getTopic,
  getTopics,
  getTopicsHistory,
  shuffleTopics,
  updateQueuedTopicActive,
  updateQueuedTopicOrder,
  updateTopic,
} from "./actions";

function* getTopicsSaga({ payload }: ActionWithPayload<{ id: string | number }>) {
  try {
    yield put(startLoading());
    const response: TopicQueue[] = yield call(api.getTopics, payload);
    yield put(stopLoading());
    yield put(getTopics.success(response));
  } catch (error) {
    yield put(getTopics.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getTopicSaga({ payload }: ActionWithPayload<{ id: string | number }>) {
  try {
    yield put(startLoading());
    const response: Topic = yield call(api.getTopic, payload);
    yield put(stopLoading());
    yield put(getTopic.success(response));
  } catch (error) {
    yield put(getTopic.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getTopicsHistorySaga({ payload }: ActionWithPayload<{ id: string | number }>) {
  try {
    yield put(startLoading());
    const response: TopicQueue[] = yield call(api.getTopicsHistory, payload);
    yield put(stopLoading());
    yield put(getTopicsHistory.success(response));
  } catch (error) {
    yield put(getTopicsHistory.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getPacksSaga() {
  try {
    yield put(startLoading());
    const response: Pack[] = yield call(api.getPacks);
    yield put(stopLoading());
    yield put(getPacks.success(response));
  } catch (error) {
    yield put(getPacks.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getPackSaga({ payload }: ActionWithPayload<{ id: string | number }>) {
  try {
    yield put(startLoading());
    const response: PackDetailed = yield call(api.getPack, payload);
    yield put(stopLoading());
    yield put(getPack.success(response));
  } catch (error) {
    yield put(getPack.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getPacksChannelSaga({ payload }: ActionWithPayload<{ id: string | number }>) {
  try {
    yield put(startLoading());
    const response: Pack[] = yield call(api.getPacksForChannel, payload);
    yield put(stopLoading());
    yield put(getPacksChannel.success(response));
  } catch (error) {
    yield put(getPacksChannel.failure(error as Error));
    yield put(stopLoading());
  }
}

function* createPackChannelSaga({ payload }: ActionWithPayload<CreatePackChannelShape>) {
  try {
    yield put(startLoading());
    const response: BaseResponse = yield call(api.subscribeChannelForPack, payload);
    yield put(stopLoading());
    yield put(createPackChannel.success({ response, pack_id: payload.pack_id }));
    yield put(getTopics.request({ id: payload.channel_id }));
  } catch (error) {
    yield put(createPackChannel.failure(error as Error));
    yield put(stopLoading());
  }
}

function* deletePackChannelSaga({ payload }: ActionWithPayload<DeletePackChannelShape>) {
  try {
    yield put(startLoading());
    const response: BaseResponse = yield call(api.unsubscribeChannelFromPack, payload);
    yield put(stopLoading());
    yield put(deletePackChannel.success({ response, pack_id: payload.pack_id }));
    yield put(getTopics.request({ id: payload.channel_id }));
  } catch (error) {
    yield put(deletePackChannel.failure(error as Error));
    yield put(stopLoading());
  }
}

function* deleteTopicChannelSaga({ payload }: ActionWithPayload<DeleteTopicChannelShape>) {
  try {
    yield put(startLoading());
    const response: BaseResponse = yield call(api.deleteTopicFromChannel, payload);
    yield put(stopLoading());
    yield put(deleteTopicChannel.success(response));
    yield put(getTopics.request({ id: payload.channel_id }));
  } catch (error) {
    yield put(deleteTopicChannel.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateQueuedTopicActiveSaga({ payload }: ActionWithPayload<PayloadWithId<UpdateQueuedTopicActiveShape>>) {
  try {
    yield put(startLoading());
    const response: TopicQueue[] = yield call(api.updateQueuedTopicActive, payload);
    yield put(stopLoading());
    yield put(updateQueuedTopicActive.success(response));
    yield put(getTopics.request({ id: payload.payload.channel_id }));
  } catch (error) {
    yield put(updateQueuedTopicActive.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateQueuedTopicOrderSaga({ payload }: ActionWithPayload<PayloadWithId<TopicQueueOrderShape[]>>) {
  try {
    yield put(startLoading());
    const response: BaseResponse = yield call(api.updateQueuedTopicOrder, payload);
    yield put(stopLoading());
    yield put(updateQueuedTopicOrder.success(response));
  } catch (error) {
    yield put(updateQueuedTopicOrder.failure(error as Error));
    yield put(stopLoading());
  }
}

function* shuffleTopicsSaga({ payload }: ActionWithPayload<{ id: string | number }>) {
  try {
    yield put(startLoading());
    const response: TopicQueue[] = yield call(api.shuffleTopics, payload);
    yield put(stopLoading());
    yield put(shuffleTopics.success(response));
  } catch (error) {
    yield put(shuffleTopics.failure(error as Error));
    yield put(stopLoading());
  }
}

function* createTopicSaga({ payload }: ActionWithPayload<TopicFormShapeToRequest>) {
  try {
    yield put(startLoading());
    const response: BaseResponse = yield call(api.createTopic, payload);
    yield put(stopLoading());
    yield put(createTopic.success(response));
    yield put(getTopics.request({ id: payload.channel_id }));
  } catch (error) {
    yield put(createTopic.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateTopicSaga({ payload }: ActionWithPayload<PayloadWithId<TopicFormShapeToRequest>>) {
  try {
    yield put(startLoading());
    const response: UpdateTopicSuccess = yield call(api.updateTopic, payload);
    yield put(stopLoading());
    yield put(updateTopic.success(response));
  } catch (error) {
    yield put(updateTopic.failure(error as Error));
    yield put(stopLoading());
  }
}

function* topicSagas() {
  yield takeLatest(getTopics.request, getTopicsSaga);
  yield takeLatest(getTopicsHistory.request, getTopicsHistorySaga);
  yield takeLatest(shuffleTopics.request, shuffleTopicsSaga);
  yield takeLatest(getPacks.request, getPacksSaga);
  yield takeLatest(getPack.request, getPackSaga);
  yield takeLatest(getPacksChannel.request, getPacksChannelSaga);
  yield takeLatest(createPackChannel.request, createPackChannelSaga);
  yield takeLatest(deletePackChannel.request, deletePackChannelSaga);
  yield takeLatest(updateQueuedTopicActive.request, updateQueuedTopicActiveSaga);
  yield takeLatest(updateQueuedTopicOrder.request, updateQueuedTopicOrderSaga);
  yield takeLatest(deleteTopicChannel.request, deleteTopicChannelSaga);
  yield takeLatest(createTopic.request, createTopicSaga);
  yield takeLatest(getTopic.request, getTopicSaga);
  yield takeLatest(updateTopic.request, updateTopicSaga);
}

export default topicSagas;
