import React, { FC, useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { LoadingSpinner } from "@shared/components";
import useQuery from "@shared/hooks/QueryHook";
import { actions } from "@containers/Auth/store";
import { setAppCookie } from "@shared/utils";
import "./index.scss";

const AuthHashTokenContainer: FC = () => {
  const dispatch = useDispatch();
  const query = useQuery();

  const { hash } = useParams();
  const coupon = query.get("coupon");

  useEffect(() => {
    if (coupon) {
      setAppCookie("coupon", coupon);
    }
    dispatch(actions.authHashToken.request({ hash }));
  }, [dispatch, hash, coupon]);

  return (
    <section className="auth-hash-token-container">
      <LoadingSpinner />
    </section>
  );
};

export default AuthHashTokenContainer;
