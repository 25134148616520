import React, { FC, useMemo } from "react";
import classnames from "classnames";
import moment from "moment";
import DatePickerOrigin, { DatePickerProps } from "rsuite/DatePicker";
import { RsRefForwardingComponent } from "rsuite/esm/@types/common";
import { is24Format } from "@shared/utils";
import "./rsuite.css";
import "./index.scss";

interface ModifiedDatePickerProps extends Omit<DatePickerProps, "value"> {
  value?: Date | null;
}

const DatePicker = DatePickerOrigin as RsRefForwardingComponent<"div", ModifiedDatePickerProps>;
interface CustomDatePickerProps {
  className?: string;
  label?: string;
  onChange: (value: moment.Moment | null) => void;
  value?: moment.Moment | null;
  disablePast?: boolean;
  disabled?: boolean;
  view?: "date" | "month" | "time";
  format?: string;
  showMeridian?: boolean;
  cleanable?: boolean;
}

const CustomDatePicker: FC<CustomDatePickerProps> = (props) => {
  const { className, label, onChange, value, disabled, cleanable, view = "date", disablePast } = props;
  const pickerProperties: ModifiedDatePickerProps = useMemo(() => {
    const propObject: ModifiedDatePickerProps = {
      disabled,
      cleanable,
      value: value ? value.toDate() : null,
      onChange: (date) => onChange(date ? moment(date) : null),
      ranges: [],
      className: "custom-datepicker",
      disabledDate: disablePast ? (date) => moment(date) < moment().subtract(1, "day") : undefined,
    };

    switch (view) {
      case "date":
        propObject.menuClassName = "custom-datepicker-menu-date";
        propObject.format = "MM/dd/yyyy";
        break;
      case "month":
        propObject.menuClassName = "custom-datepicker-menu-month";
        propObject.format = "MMM, yyyy";
        propObject.size = "lg";
        break;
      case "time":
        propObject.menuClassName = "custom-datepicker-menu-time";
        propObject.format = is24Format ? "HH:00" : "hh:00 aa";
        propObject.showMeridian = !is24Format;
        propObject.placeholder = "00:00";
    }
    return propObject;
  }, [value, onChange, disabled, view, cleanable, disablePast]);

  return (
    <div className={classnames("custom-datepicker-wrapper input-wrapper", className, { disabled })}>
      {label && <label className="custom-input-label">{label}</label>}
      <DatePicker {...pickerProperties} />
    </div>
  );
};
export default CustomDatePicker;
