import { User } from "../models";
import { tokenHandler } from "../utils";

export const getUserPermissions = (channel_id: number): string[] => {
  const user: User = tokenHandler.getUser();
  if (!user || !user.user_roles) {
    return [];
  }

  const permissions: string[] = [];
  user.user_roles?.forEach((user_role) => {
    if (user_role.channel_id === channel_id) {
      permissions.push(...user_role.role.permissions.map((p) => p.name));
    }
  });

  return permissions;
};

export const checkPermission = (permission: string, channel_id: number | null): boolean => {
  if (!channel_id) {
    return true;
  }
  const userPermissions = getUserPermissions(channel_id);

  return userPermissions.includes(permission);
};

export const checkBillingPermission = (user: User | null): boolean => (user ? user.has_active_subscription : false);
