import React, { useCallback } from "react";
import classnames from "classnames";
import { useModal, useHideModal } from "@shared/hooks";
import { Button } from "@shared/components";

import "./index.scss";

const ModalWrapper = () => {
  const { modal } = useModal();
  const { handleOnClose } = useHideModal();

  const localCancel = useCallback(() => {
    modal.onCancel && modal.onCancel();
    handleOnClose();
  }, [modal, handleOnClose]);

  const localConfirm = useCallback(() => {
    modal.onConfirm && modal.onConfirm();
    handleOnClose();
  }, [modal, handleOnClose]);

  return modal.show ? (
    <>
      <div className="modal-overlay" onClick={() => modal.closeOnOutside && handleOnClose()} />
      <div className={classnames("modal-wrapper", modal.wrapperClass)} style={{ width: modal.modalWidth || "480px" }}>
        {modal.title && <div className="modal-title">{modal.title}</div>}
        {modal.body && <div className="modal-body">{modal.body}</div>}
        {(modal.onConfirm || modal.onCancel) && (
          <div className="modal-buttons">
            <Button buttonStyle="accent" buttonColor="blue" type="button" onClick={localCancel}>
              {modal.cancelText || "Cancel"}
            </Button>
            {!modal.showOneButton ? (
              <Button type="button" buttonColor="red" onClick={localConfirm}>
                {modal.confirmText || "Confirm"}
              </Button>
            ) : null}
          </div>
        )}
      </div>
    </>
  ) : null;
};

export default ModalWrapper;
