export enum PERMISSION {
  EDIT_CHANNEL = "Edit channel",
  DELETE_CHANNEL = "Delete channel",

  VIEW_QUEUE = "View queue",

  CREATE_TOPIC = "Create topic",
  EDIT_TOPIC = "Edit topic",

  TRANSFER_OWNERSHIP = "Transfer ownership",
}
