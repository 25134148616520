import { Icon } from "@shared/components";
import React from "react";
import { useToasts } from "react-toast-notifications";

import { IToastProps } from "../../interfaces";
import "./index.scss";

const Toast: React.FC<IToastProps> = (props) => {
  const { appearance, children, onDismiss, placement } = props;
  const { removeAllToasts } = useToasts();

  return (
    <div className={`notification ${appearance} ${placement}`}>
      <div className="notification-content" onClick={removeAllToasts}>
        <div className={`notification-content-icon ${appearance}`}>
          <Icon
            type={
              appearance === "info"
                ? "delete"
                : appearance === "success"
                ? "notification-success"
                : "notification-error"
            }
          />
        </div>
        <div className="notification-content-info">
          <div className="notification-message">{children}</div>
        </div>
        {appearance === "info" && (
          <div className="notification-content-additional">
            <div className="additional-button" onClick={() => onDismiss()}>
              Undo
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Toast;
