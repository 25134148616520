import React from "react";
import classnames from "classnames";

import { TableProperty } from "../interface";
import "./index.scss";

export interface HeadProps<T> {
  properties: TableProperty<T>[];
  total?: number;
}

function Head<T>(props: HeadProps<T>) {
  const { properties } = props;

  return (
    <div className="table-header">
      {properties.map((prop) => (
        <div key={prop.title} className={classnames("table-header-cell", prop.headerClassName)}>
          <div>{prop.title}</div>
        </div>
      ))}
    </div>
  );
}

export default Head;
