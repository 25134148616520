import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";
import { getUser, getWorkspace, navigate, startLoading, stopLoading } from "@shared/store/actions";
import { NameOfRoutes } from "@shared/constants";
import { tokenHandler } from "@shared/utils";

import { AuthActionTypes } from "./constants";
import { logout, login, addSlackWorkspace, authHashToken } from "./actions";
import api from "../api";

function* logoutSaga() {
  yield localStorage.clear();
  yield put(logout.success());
  yield put(getUser.success(null));
  yield put(getWorkspace.success(null));
  yield put(navigate(NameOfRoutes.AUTH));
}

function* loginSaga({ payload }: AnyAction) {
  try {
    yield put(startLoading());
    const { token } = yield call(api.login, payload);
    yield tokenHandler.set(token);
    yield put(login.success());
    yield put(stopLoading());
  } catch (error) {
    yield put(login.failure(error as Error));
    yield put(stopLoading());
  }
}

function* addSlackWorkspaceSaga({ payload }: AnyAction) {
  try {
    yield put(startLoading());
    const { token } = yield call(api.addSlackWorkspace, payload);
    yield tokenHandler.set(token);
    yield put(addSlackWorkspace.success());
    yield put(stopLoading());
    yield put(navigate("/"));
  } catch (error) {
    yield put(addSlackWorkspace.failure(error as Error));
    yield put(stopLoading());
  }
}

function* authHashTokenSaga({ payload }: AnyAction) {
  try {
    yield put(startLoading());
    const { token } = yield call(api.authHashToken, payload);
    if (token) {
      yield tokenHandler.set(token);
      yield put(authHashToken.success(token));
    }
    yield put(stopLoading());
    yield put(navigate(NameOfRoutes.INSTALL_SLACK_APPLICATION + "?autoLogin=true&redirectStripe=true"));
  } catch (error) {
    yield put(authHashToken.failure(error as Error));
    yield put(stopLoading());
  }
}

function* authSagas() {
  yield takeLatest(AuthActionTypes.LOGOUT, logoutSaga);
  yield takeLatest(AuthActionTypes.LOGIN, loginSaga);
  yield takeLatest(AuthActionTypes.ADD_SLACK_WORKSPACE, addSlackWorkspaceSaga);
  yield takeLatest(AuthActionTypes.AUTH_HASH_TOKEN, authHashTokenSaga);
}

export default authSagas;
