import React from "react";
import { useLayer, useHover, Arrow } from "react-laag";
import "./index.scss";

interface TooltipProps {
  children: React.ReactNode;
  content?: string;
}

function Tooltip({ children, content }: TooltipProps) {
  const [isOver, hoverProps] = useHover();

  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: isOver,
  });

  return (
    <>
      <span {...triggerProps} {...hoverProps} className="tooltip-top-level">
        {children}
      </span>
      {isOver &&
        renderLayer(
          <div className="tooltip" {...layerProps}>
            {content}
            <Arrow {...arrowProps} />
          </div>,
        )}
    </>
  );
}

export default Tooltip;
