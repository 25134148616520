import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import { BillingStateType } from "../interface";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

export const initialState: BillingStateType = {
  billingURL: null,
};

const reducer = createReducer<BillingStateType, Action>(initialState).handleAction(
  actions.getBillingPortalUrl.success,
  (state, action) =>
    produce(state, (nextState) => {
      nextState.billingURL = action.payload?.url ?? null;
    }),
);
export { reducer as BillingReducer };
