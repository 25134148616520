import React from "react";
import type { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { authentificated } from "@containers/Auth/store/selectors";

import { NameOfRoutes } from "../constants";

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const isAuthenticated = useSelector(authentificated());
  if (!isAuthenticated) {
    return <Navigate to={NameOfRoutes.AUTH} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
