import { createAsyncAction } from "typesafe-actions";

import { AuthActionTypes } from "./constants";

export const login = createAsyncAction(
  AuthActionTypes.LOGIN,
  AuthActionTypes.LOGIN_SUCCESS,
  AuthActionTypes.LOGIN_FAILURE,
)<{ code: string }, undefined, Error>();

export const logout = createAsyncAction(
  AuthActionTypes.LOGOUT,
  AuthActionTypes.LOGOUT_SUCCESS,
  AuthActionTypes.LOGOUT_FAILURE,
)<undefined, undefined, Error>();

export const addSlackWorkspace = createAsyncAction(
  AuthActionTypes.ADD_SLACK_WORKSPACE,
  AuthActionTypes.ADD_SLACK_WORKSPACE_SUCCESS,
  AuthActionTypes.ADD_SLACK_WORKSPACE_FAILURE,
)<{ code: string }, undefined, Error>();

export const authHashToken = createAsyncAction(
  AuthActionTypes.AUTH_HASH_TOKEN,
  AuthActionTypes.AUTH_HASH_TOKEN_SUCCESS,
  AuthActionTypes.AUTH_HASH_TOKEN_FAILURE,
)<{ hash: string }, { token: string | null }, Error>();
