import { getUser, navigate, startLoading, stopLoading } from "@shared/store/actions";
import { call, put, takeLatest } from "redux-saga/effects";
import { Channel, ChannelFormShapeToRequest, SlackChannel, SlackUser } from "@shared/models";
import { ActionWithPayload, PayloadWithId } from "@shared/interfaces";
import { FindShape, GetChannelsShape } from "@containers/Channel/interface/ChannelForm.interface";
import { NameOfRoutes } from "@shared/constants";
import { BaseResponse } from "@shared/interfaces/BaseResponse.interface";

import {
  createChannel,
  deleteChannel,
  findSlackChannels,
  findSlackUsers,
  getChannel,
  getChannels,
  updateChannel,
} from "./actions";
import api from "../api";

function* getChannelsSaga({ payload }: ActionWithPayload<GetChannelsShape>) {
  try {
    yield put(startLoading());
    const response: Channel[] = yield call(api.getChannels, payload);
    yield put(stopLoading());
    yield put(getChannels.success(response));
  } catch (error) {
    yield put(getChannels.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getChannelSaga({ payload }: ActionWithPayload<{ id: string | number }>) {
  try {
    yield put(startLoading());
    const response: Channel = yield call(api.getChannel, payload);
    yield put(stopLoading());
    yield put(getChannel.success(response));
  } catch (error) {
    yield put(getChannel.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getSlackChannelsSaga({ payload }: ActionWithPayload<FindShape>) {
  try {
    yield put(startLoading());
    const response: SlackChannel[] = yield call(api.findSlackChannels, payload);
    yield put(stopLoading());
    yield put(findSlackChannels.success(response));
  } catch (error) {
    yield put(findSlackChannels.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getSlackUsersSaga({ payload }: ActionWithPayload<FindShape>) {
  try {
    yield put(startLoading());
    const response: SlackUser[] = yield call(api.findSlackUsers, payload);
    yield put(stopLoading());
    yield put(findSlackUsers.success(response));
  } catch (error) {
    yield put(findSlackUsers.failure(error as Error));
    yield put(stopLoading());
  }
}

function* createChannelSaga({ payload }: ActionWithPayload<ChannelFormShapeToRequest>) {
  try {
    yield put(startLoading());
    const response: BaseResponse = yield call(api.createChannel, payload);
    yield put(stopLoading());
    yield put(createChannel.success(response));
    yield put(getUser.success(null));
    yield put(navigate(`${NameOfRoutes.WORKSPACE}`));
  } catch (error) {
    yield put(createChannel.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateChannelSaga({ payload }: ActionWithPayload<Required<PayloadWithId<ChannelFormShapeToRequest>>>) {
  try {
    yield put(startLoading());
    const response: Channel = yield call(api.updateChannel, payload);
    yield put(stopLoading());
    yield put(updateChannel.success(response));
    yield put(getUser.success(null));
    yield put(navigate(`${NameOfRoutes.WORKSPACE}`));
  } catch (error) {
    yield put(updateChannel.failure(error as Error));
    yield put(stopLoading());
  }
}

function* deleteChannelSaga({ payload }: ActionWithPayload<{ id: string | number }>) {
  try {
    yield put(startLoading());
    const response: BaseResponse = yield call(api.deleteChannel, payload);
    yield put(stopLoading());
    yield put(deleteChannel.success(response));
    yield put(navigate(`${NameOfRoutes.WORKSPACE}`));
  } catch (error) {
    yield put(deleteChannel.failure(error as Error));
    yield put(stopLoading());
  }
}

function* channelSagas() {
  yield takeLatest(getChannels.request, getChannelsSaga);
  yield takeLatest(getChannel.request, getChannelSaga);
  yield takeLatest(findSlackChannels.request, getSlackChannelsSaga);
  yield takeLatest(findSlackUsers.request, getSlackUsersSaga);
  yield takeLatest(createChannel.request, createChannelSaga);
  yield takeLatest(updateChannel.request, updateChannelSaga);
  yield takeLatest(deleteChannel.request, deleteChannelSaga);
}

export default channelSagas;
