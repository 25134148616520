export enum SharedActionTypes {
  START_LOADING = "@@SHARED/START_LOADING",
  STOP_LOADING = "@@SHARED/STOP_LOADING",
  ADD_LOADING_TYPE = "@@SHARED/ADD_LOADING_TYPE",
  REMOVE_LOADING_TYPE = "@@SHARED/REMOVE_LOADING_TYPE",

  ADD_LOADING_SECTION = "@@SHARED/ADD_LOADING_SECTION",
  REMOVE_LOADING_SECTION = "@@SHARED/REMOVE_LOADING_SECTION",

  SHOW_NOTIFICATION = "@@SHARED/SHOW_NOTIFICATION",
  NAVIGATE = "@@SHARED/NAVIGATE",

  GET_USER = "@@SHARED/GET_USER",
  GET_USER_SUCCESS = "@@SHARED/GET_USER_SUCCESS",
  GET_USER_FAILURE = "@@SHARED/GET_USER_FAILURE",

  GET_WORKSPACE = "@@SHARED/GET_WORKSPACE",
  GET_WORKSPACE_SUCCESS = "@@SHARED/GET_WORKSPACE_SUCCESS",
  GET_WORKSPACE_FAILURE = "@@SHARED/GET_WORKSPACE_FAILURE",

  GET_STRIPE_URL = "@@SHARED/GET_STRIPE_URL",
  GET_STRIPE_URL_SUCCESS = "@@SHARED/GET_STRIPE_URL_SUCCESS",
  GET_STRIPE_URL_FAILURE = "@@SHARED/GET_STRIPE_URL_FAILURE",

  GET_STRIPE_DISCOUNT_URL = "@@SHARED/GET_STRIPE_DISCOUNT_URL",
  GET_STRIPE_DISCOUNT_URL_SUCCESS = "@@SHARED/GET_STRIPE_DISCOUNT_URL_SUCCESS",
  GET_STRIPE_DISCOUNT_URL_FAILURE = "@@SHARED/GET_STRIPE_DISCOUNT_URL_FAILURE",

  SHOW_OVERLAY = "@@SHARED/SHOW_OVERLAY",
  HIDE_OVERLAY = "@@SHARED/HIDE_OVERLAY",

  SHOW_MODAL = "@@SHARED/SHOW_MODAL",
  HIDE_MODAL = "@@SHARED/HIDE_MODAL",
}
