import React, { lazy } from "react";
import { useRoutes } from "react-router-dom";
import { NameOfRoutes } from "@shared/constants";

import { AuthWrapper } from "../../components";
import { Loadable } from "../../../../shared/components";

const LoginContainer = Loadable(lazy(() => import("../LoginContainer/LoginContainer")));

const routes = [
  {
    path: "/",
    element: <LoginContainer />,
  },
  {
    path: `${NameOfRoutes.SIGN_UP}`,
    element: <p>SIGN_UP</p>,
  },
];

const AuthContainer = () => {
  const content = useRoutes(routes);

  return <AuthWrapper>{content}</AuthWrapper>;
};

export default AuthContainer;
