import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { EXTERNAL_SYSTEMS } from "@shared/constants";

import configureStore from "./store";
import "./assets/styles/index.scss";
import App from "./containers/App/App";
import * as serviceWorker from "./serviceWorker";
import history from "./shared/history";

const { store } = configureStore(history);

if (process.env.REACT_APP_ENV === "dev") {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, { trackHooks: true });
}

if (["production", "qa"].includes(process.env.REACT_APP_ENV || "")) {
  Sentry.init({
    dsn: EXTERNAL_SYSTEMS.SENTRY_DSN,
  });
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
