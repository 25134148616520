import React, { useEffect } from "react";
import { generatePath } from "react-router";
import { ToastProvider } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import ApplicationContainer from "@containers/App/ApplicationContainer";
import { NameOfRoutes, NamesOfParentRoutes } from "@shared/constants";
import { actions as sharedActions, selectors as sharedSelectors } from "@shared/store";
import { Toast, Notification, OverlayWrapper, ModalWrapper } from "@shared/components/";
import { initHubspotChat } from "@shared/utils/hubspot";
import { AuthGuard } from "@shared/guards";

import { AuthContainer, WorkspaceAuthContainer, InstallApplicationContainer, AuthHashTokenContainer } from "../Auth";

import "./index.scss";

const routes = [
  {
    path: `${NamesOfParentRoutes.AUTH}`,
    element: <AuthContainer />,
  },
  {
    path: `${NameOfRoutes.ADD_SLACK_WORKSPACE}`,
    element: <WorkspaceAuthContainer />,
  },
  {
    path: `${NameOfRoutes.INSTALL_SLACK_APPLICATION}`,
    element: <InstallApplicationContainer />,
  },
  {
    path: `${NameOfRoutes.AUTH_HASH_TOKEN}`,
    element: <AuthHashTokenContainer />,
  },
  {
    path: `${NamesOfParentRoutes.AUTHORIZED}`,
    element: (
      <AuthGuard>
        <ApplicationContainer />
      </AuthGuard>
    ),
  },
];

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const notification = useSelector(sharedSelectors.getNotification());
  const navigateURL = useSelector(sharedSelectors.getNavigateURL());

  useEffect(() => {
    if (navigateURL) {
      navigate(navigateURL);
      dispatch(sharedActions.navigate(""));
    }
    if (location.pathname === "/") {
      dispatch(sharedActions.navigate(generatePath(`${NameOfRoutes.WORKSPACE}`)));
    }
  }, [navigateURL, dispatch, navigate, location.pathname]);

  useEffect(() => {
    (async () => {
      await initHubspotChat();
    })();
  }, []);

  const content = useRoutes(routes);
  return (
    <div className="App">
      <ToastProvider components={{ Toast }}>
        <Notification notification={notification} />
        <OverlayWrapper />
        <ModalWrapper />
        {content}
      </ToastProvider>
    </div>
  );
};

export default App;
