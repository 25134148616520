import React, { FC, useEffect } from "react";
import useQuery from "@shared/hooks/QueryHook";
import { useDispatch } from "react-redux";
import { actions } from "@containers/Auth/store";
import { LoadingSpinner } from "@shared/components";
import { setAppCookie } from "@shared/utils";
import "./index.scss";

const WorkspaceAuthContainer: FC = () => {
  const dispatch = useDispatch();

  const query = useQuery();
  const code = query.get("code");

  useEffect(() => {
    if (code) {
      dispatch(actions.addSlackWorkspace.request({ code }));
      setAppCookie("installedApp", "Lunchkin has been successfully installed.");
    }
  }, [dispatch, code]);

  return (
    <section className="workspace-auth">
      <LoadingSpinner />
    </section>
  );
};

export default WorkspaceAuthContainer;
