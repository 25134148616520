import { API, METHODS, SCHEMAS } from "@shared/constants";
import { API_ENUM } from "@shared/constants/api";
import { SchemasMap } from "@shared/interfaces";
import { mapToSchema } from "@shared/utils";

const schemaShapes: SchemasMap<keyof typeof API.SHARED> = {
  [API_ENUM.GET_USER]: {
    request: {
      name: `${API.SHARED.GET_USER}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${API.SHARED.GET_USER}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: {},
    },
  },
  [API_ENUM.GET_WORKSPACE]: {
    request: {
      name: `${API.SHARED.GET_WORKSPACE}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${API.SHARED.GET_WORKSPACE}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: {},
    },
  },
  [API_ENUM.GET_STRIPE_URL]: {
    request: {
      name: `${API.SHARED.GET_STRIPE_URL}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: {},
    },
    response: {
      name: `${API.SHARED.GET_STRIPE_URL}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: {},
    },
  },
};

export default mapToSchema(schemaShapes);
