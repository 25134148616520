import { createAction, createAsyncAction } from "typesafe-actions";
import { User, Workspace } from "@shared/models";

import { LoadingType, ModalProps, Notification, OverlayProps, Stripe } from "../interfaces";
import { SharedActionTypes } from "./constants";

export const startLoading = createAction(SharedActionTypes.START_LOADING)();
export const stopLoading = createAction(SharedActionTypes.STOP_LOADING)();
export const addLoadingType = createAction(SharedActionTypes.ADD_LOADING_TYPE)<LoadingType>();
export const removeLoadingType = createAction(SharedActionTypes.REMOVE_LOADING_TYPE)<string>();
export const addLoadingSection = createAction(SharedActionTypes.ADD_LOADING_SECTION)<{
  loadingSection: string;
  requestName: string;
}>();
export const removeLoadingSection = createAction(SharedActionTypes.REMOVE_LOADING_SECTION)<{
  loadingSection: string;
  requestName: string;
}>();
export const showNotification = createAction(SharedActionTypes.SHOW_NOTIFICATION)<Notification>();

export const navigate = createAction(SharedActionTypes.NAVIGATE)<string>();

export const getUser = createAsyncAction(
  SharedActionTypes.GET_USER,
  SharedActionTypes.GET_USER_SUCCESS,
  SharedActionTypes.GET_USER_FAILURE,
)<undefined, User | null, Error>();

export const getWorkspace = createAsyncAction(
  SharedActionTypes.GET_WORKSPACE,
  SharedActionTypes.GET_WORKSPACE_SUCCESS,
  SharedActionTypes.GET_WORKSPACE_FAILURE,
)<undefined, Workspace | null, Error>();

export const getStripeUrl = createAsyncAction(
  SharedActionTypes.GET_STRIPE_URL,
  SharedActionTypes.GET_STRIPE_URL_SUCCESS,
  SharedActionTypes.GET_STRIPE_URL_FAILURE,
)<Stripe, { url: string }, Error>();

export const getStripeDiscountUrl = createAsyncAction(
  SharedActionTypes.GET_STRIPE_DISCOUNT_URL,
  SharedActionTypes.GET_STRIPE_DISCOUNT_URL_SUCCESS,
  SharedActionTypes.GET_STRIPE_DISCOUNT_URL_FAILURE,
)<Stripe, { url: string }, Error>();

export const showOverlay = createAction(SharedActionTypes.SHOW_OVERLAY)<OverlayProps>();
export const hideOverlay = createAction(SharedActionTypes.HIDE_OVERLAY)();

export const showModal = createAction(SharedActionTypes.SHOW_MODAL)<ModalProps>();
export const hideModal = createAction(SharedActionTypes.HIDE_MODAL)();
