import React, { FC, useEffect } from "react";
import { Icon } from "@shared/components";
import "./index.scss";
import { IconType } from "@shared/components/Icon/Icon";

interface LoadingSpinnerProps {
  redirectTo?: string;
  spinnerType?: IconType;
}

const LoadingSpinner: FC<LoadingSpinnerProps> = (props) => {
  const { redirectTo, spinnerType } = props;

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);

  return (
    <div className="loader-spinner-wrapper">
      <Icon type={spinnerType ?? "spinner"} />
    </div>
  );
};

export default LoadingSpinner;
