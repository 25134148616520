import { FindShape, GetChannelsShape } from "@containers/Channel/interface/ChannelForm.interface";
import { ChannelFormShapeToRequest } from "@shared/models";
import { ActionWithId, PayloadWithId } from "@shared/interfaces";

import { request } from "../../../shared/utils";
import { API, METHODS } from "../../../shared/constants";

export default {
  getChannels: (payload: GetChannelsShape) => request(METHODS.GET, API.CHANNEL.GET_CHANNELS)({ params: payload }),
  getChannel: (payload: Required<ActionWithId>) => {
    return request(METHODS.GET, API.CHANNEL.GET_CHANNEL(payload.id))();
  },
  findSlackChannels: (payload: FindShape) => request(METHODS.GET, API.CHANNEL.FIND_SLACK_CHANNELS)({ params: payload }),
  findSlackUsers: (payload: FindShape) => request(METHODS.GET, API.CHANNEL.FIND_SLACK_USERS)({ params: payload }),
  createChannel: (payload: ChannelFormShapeToRequest) => request(METHODS.POST, API.CHANNEL.CREATE_CHANNEL)(payload),
  updateChannel: (payload: PayloadWithId<ChannelFormShapeToRequest>) => {
    return request(METHODS.PUT, API.CHANNEL.UPDATE_CHANNEL(payload.id))(payload.payload);
  },
  deleteChannel: (payload: Required<ActionWithId>) => {
    return request(METHODS.DELETE, API.CHANNEL.DELETE_CHANNEL(payload.id))();
  },
};
