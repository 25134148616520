import React, { useCallback, useMemo } from "react";
import { FormikProps } from "formik";
import moment from "moment";
import classnames from "classnames";
import {
  AutocompleteInput,
  ErrorMessage,
  Input,
  Select,
  MultiSelect,
  AutocompleteSelect,
  AutocompleteMultiSelect,
  RichTextArea,
} from "@shared/components/Common/";
import { Option } from "@shared/interfaces";
import { DatePicker } from "@shared/components/Common/Field/DatePicker";

import {
  TextFieldItem,
  Handlers,
  AutocompleteInputField,
  NoticeFieldItem,
  SelectFieldItem,
  TimePickerFieldItem,
  DatePickerFieldItem,
  AutocompleteSelectField,
  RichTextAreaFieldItem,
} from "../../interfaces";

// eslint-disable-next-line
type AnyFormikProps = FormikProps<any> & Handlers;

export const GenerateTextField: React.FunctionComponent<TextFieldItem & { formikProps: AnyFormikProps }> = (props) => {
  const {
    formikProps: { errors, touched, handleBlur, handleChange, values },
    wrapperClass,
    name,
    placeholder,
    disabled,
    type,
    label,
    onChangeField,
  } = props;

  const handleInputChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      handleChange(e);
      onChangeField && onChangeField(name, e.currentTarget.value, values);
    },
    [onChangeField, handleChange, values, name],
  );

  return (
    <div className={classnames(wrapperClass, { error: errors[name] && touched[name] })} id={name}>
      <Input
        type={type}
        label={label}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleInputChange}
        onBlur={handleBlur}
        value={values[name]}
      />
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};

export const GenerateAutocompleteInput: React.FunctionComponent<
  AutocompleteInputField & { formikProps: AnyFormikProps }
> = (props) => {
  const {
    formikProps: { errors, touched, setFieldValue, values, setFieldTouched },
    handlers,
    name,
    type,
    onChangeField,
    wrapperClass,
    onValueSelect,
    isLowerCase,
    ...fieldProps
  } = props;

  if (!handlers) return null;
  if (handlers && !handlers[name]) return null;
  const { getData, clearData, selectData, prepareOptionFunction } = handlers[name];
  return (
    <div key={name} id={name} className={classnames(wrapperClass)}>
      <AutocompleteInput
        {...fieldProps}
        name={name}
        inputType={type}
        value={isLowerCase ? values[name].toLowerCase() : values[name]}
        getData={getData}
        clearData={clearData}
        selectData={selectData}
        onValueSelect={onValueSelect}
        prepareOptionFunction={prepareOptionFunction}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setFieldValue={(field: string, value: any, shouldValidate?: boolean | undefined) => {
          setFieldValue(field, value, shouldValidate);
          setFieldTouched(field, true);
          onChangeField && onChangeField(field, value, setFieldValue);
        }}
      />
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};

export const GenerateNoticeField: React.FunctionComponent<NoticeFieldItem> = (props) => {
  const { wrapperClass, label, name } = props;

  return (
    <div className={classnames(wrapperClass)} id={name}>
      <div>{label}</div>
    </div>
  );
};

export const GenerateSelect: React.FunctionComponent<SelectFieldItem & { formikProps: AnyFormikProps }> = (props) => {
  const {
    formikProps: { errors, touched, setFieldValue, values, setFieldTouched },
    wrapperClass,
    disabled,
    label,
    name,
    options,
    placeholder,
    isSearchable = true,
  } = props;

  const handleChange = (option: Option) => {
    setFieldValue(name, option);
  };

  const handleInputChange = () => {
    setFieldTouched(name, true, false);
  };

  const currentOptionValue = useMemo(() => {
    return options.find((option) => option.value === values[name]) || null;
  }, [options, name, values]);

  return (
    <div
      className={classnames("render-select", wrapperClass, {
        error: errors[name] && touched[name],
      })}
      key={name}
      id={name}>
      {label && <label className={classnames({ disabled })}>{label}</label>}
      <div className={classnames("select", { disabled })}>
        <Select
          isDisabled={disabled || !options.length}
          components={{
            IndicatorSeparator: () => null,
          }}
          isSearchable={isSearchable}
          onChange={(option) => handleChange(option as Option)}
          onInputChange={handleInputChange}
          value={currentOptionValue}
          options={options}
          name={name}
          placeholder={placeholder || "Start typing here"}
          noOptionsMessage={() => "No matches found"}
        />
      </div>
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};

export const GenerateTimepicker: React.FunctionComponent<TimePickerFieldItem & { formikProps: AnyFormikProps }> = (
  props,
) => {
  const {
    name,
    wrapperClass,
    label,
    disabled,
    format,
    onChange,
    showMeridian,
    formikProps: { setFieldValue, values, touched, errors },
  } = props;

  const handleChange = useCallback(
    (value: moment.Moment | null) => {
      const newValue = value ? value.toDate() : null;
      setFieldValue(name, newValue);
      onChange && onChange(newValue);
    },
    [setFieldValue, name, onChange],
  );

  return (
    <div key={name} id={name}>
      <DatePicker
        label={label}
        className={wrapperClass}
        onChange={handleChange}
        disabled={disabled}
        format={format}
        value={values[name] ? moment(values[name]) : null}
        view="time"
        showMeridian={showMeridian}
      />
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};

export const GenerateDatepicker: React.FunctionComponent<DatePickerFieldItem & { formikProps: AnyFormikProps }> = (
  props,
) => {
  const {
    name,
    wrapperClass,
    label,
    disabled,
    disablePast,
    formikProps: { setFieldValue, values, touched, errors },
  } = props;

  const handleChange = useCallback(
    (value: moment.Moment | null) => {
      setFieldValue(name, value ? value.toDate() : null);
    },
    [setFieldValue, name],
  );

  return (
    <div key={name} id={name}>
      <DatePicker
        label={label}
        className={wrapperClass}
        onChange={handleChange}
        disablePast={disablePast}
        disabled={disabled}
        value={values[name] ? moment(values[name]) : null}
      />
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};

export const GenerateMultiSelect: React.FunctionComponent<SelectFieldItem & { formikProps: AnyFormikProps }> = (
  props,
) => {
  const {
    formikProps: { errors, touched, setFieldValue, values, setFieldTouched },
    wrapperClass,
    disabled,
    label,
    name,
    options,
    placeholder,
  } = props;

  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (option: any) => {
      setFieldTouched(name, true);
      setFieldValue && setFieldValue(name, option);
    },
    [name, setFieldTouched, setFieldValue],
  );

  const handleRemoveValue = useCallback(
    (id: string) => {
      setFieldValue &&
        setFieldValue(
          name,
          (values[name] || []).filter(({ value }: { label: string; value: string }) => value !== id),
        );
      setFieldTouched(name, true);
    },
    [setFieldTouched, name, values, setFieldValue],
  );

  return (
    <div
      className={classnames("render-select", wrapperClass, {
        error: errors[name] && touched[name],
      })}
      key={name}
      id={name}>
      {label && <label className={classnames({ disabled })}>{label}</label>}
      <div className={classnames("select", { disabled })}>
        <MultiSelect
          options={options.map((o) => ({ label: o.label, value: String(o.value || "") })) || []}
          name={name}
          placeholder={placeholder}
          isDisabled={disabled}
          onChange={handleChange}
          value={values[name]}
          handleRemoveValue={handleRemoveValue}
        />
      </div>
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};

export const GenerateSelectSearch: React.FunctionComponent<AutocompleteSelectField & { formikProps: AnyFormikProps }> =
  (props) => {
    const {
      formikProps: { errors, touched, setFieldValue, values, setFieldTouched },
      wrapperClass,
      disabled,
      label,
      name,
      options,
      placeholder,
      handlers,
      initialValue,
      onFieldChange,
      menuWrapper,
    } = props;

    const { getData, selectData, prepareOptionFunction } = (handlers || {})[name];
    const handleChange = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (option: any, originObject?: unknown) => {
        setFieldTouched(name, true);
        setFieldValue && setFieldValue(name, option);
        onFieldChange?.(option, originObject);
      },
      [name, setFieldTouched, setFieldValue, onFieldChange],
    );

    const handleRemoveValue = useCallback(
      (id: string) => {
        setFieldValue &&
          setFieldValue(
            name,
            (values[name] || []).filter(({ value }: { label: string; value: string }) => value !== id),
          );
        setFieldTouched(name, true);
      },
      [setFieldTouched, name, values, setFieldValue],
    );

    return (
      <div
        className={classnames("render-select", wrapperClass, {
          error: errors[name] && touched[name],
        })}
        key={name}
        id={name}>
        {label && <label className={classnames({ disabled })}>{label}</label>}
        <div className={classnames("select", { disabled })}>
          <AutocompleteSelect
            options={options.map((o) => ({ label: o.label, value: String(o.value || "") })) || []}
            name={name}
            placeholder={placeholder}
            isDisabled={disabled}
            getData={getData}
            selectData={selectData}
            onChange={handleChange}
            value={values[name]}
            handleRemoveValue={handleRemoveValue}
            prepareOptionFunction={prepareOptionFunction}
            initialValue={initialValue}
            menuWrapper={menuWrapper}
          />
        </div>
        <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
      </div>
    );
  };

export const GenerateSelectSearchMulti: React.FunctionComponent<
  AutocompleteSelectField & { formikProps: AnyFormikProps }
> = (props) => {
  const {
    formikProps: { errors, touched, setFieldValue, values, setFieldTouched },
    wrapperClass,
    disabled,
    label,
    name,
    options,
    placeholder,
    handlers,
    menuWrapper,
    isLockedLogic,
    availableOptions,
    tooltipText,
    onLockedOptionClick,
    staticOptions,
  } = props;

  const { getData, selectData, prepareOptionFunction, selectTotalCount } = (handlers || {})[name] || {};

  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (option: any) => {
      setFieldTouched(name, true);
      setFieldValue && setFieldValue(name, option);
    },
    [name, setFieldTouched, setFieldValue],
  );

  const handleRemoveValue = useCallback(
    (id: string) => {
      setFieldValue &&
        setFieldValue(
          name,
          (values[name] || []).filter(({ value }: { label: string; value: string }) => value !== id),
        );
      setFieldTouched(name, true);
    },
    [setFieldTouched, name, values, setFieldValue],
  );

  return (
    <div
      className={classnames("render-select", wrapperClass, {
        error: errors[name] && touched[name],
      })}
      key={name}
      id={name}>
      {label && <label className={classnames({ disabled })}>{label}</label>}
      <div className={classnames("select", { disabled })}>
        <AutocompleteMultiSelect
          options={options.map((o) => ({ label: o.label, value: String(o.value || "") })) || []}
          staticOptions={staticOptions}
          onLockedOptionClick={onLockedOptionClick}
          name={name}
          placeholder={placeholder}
          isDisabled={disabled}
          getData={getData}
          selectData={selectData}
          onChange={handleChange}
          value={values[name]}
          handleRemoveValue={handleRemoveValue}
          menuWrapper={menuWrapper}
          prepareOptionFunction={prepareOptionFunction}
          isLockedLogic={isLockedLogic}
          availableOptions={availableOptions}
          tooltipText={tooltipText}
          selectTotalCount={selectTotalCount}
        />
      </div>
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};

export const GenerateRichTextarea: React.FunctionComponent<RichTextAreaFieldItem & { formikProps: AnyFormikProps }> = (
  props,
) => {
  const {
    name,
    inputClass,
    label,
    onChangeField,
    disabled,
    formikProps: { values, touched, errors, setFieldValue, setFieldTouched },
  } = props;

  return (
    <div key={name} id={name}>
      <RichTextArea
        name={name}
        label={label}
        className={inputClass}
        value={values[name]}
        disabled={disabled}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setFieldValue={(field: string, value: any, shouldValidate?: boolean | undefined) => {
          setFieldValue(field, value, shouldValidate);
          setFieldTouched(field, true);
          onChangeField && onChangeField(field, value, setFieldValue);
        }}
        isTouched={!!touched[name]}
      />
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};
