import React, { FC } from "react";
import { Button } from "@shared/components";

import "./index.scss";

export interface InnerModalProps {
  title: string;
  subtitle?: string;
  cancelText?: string;
  confirmText?: string;
  cancelFunction: () => void;
  confirmFunction: () => void;
}

const InnerModal: FC<InnerModalProps> = (props) => {
  const { title, subtitle, cancelText, confirmText, cancelFunction, confirmFunction } = props;
  return (
    <div className="inner-modal">
      <div className="modal-overlay" />
      <div className="modal-wrapper">
        <div className="modal-title">{title}</div>
        {subtitle && <div className="modal-body">{subtitle}</div>}
        <div className="modal-buttons">
          <Button buttonStyle="accent" buttonColor="blue" type="button" onClick={cancelFunction}>
            {cancelText ? cancelText : "Cancel"}
          </Button>
          <Button type="button" buttonColor="red" onClick={confirmFunction}>
            {confirmText ? confirmText : "Confirm"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InnerModal;
