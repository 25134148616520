import { API_ENUM } from "@shared/constants/api";
import {
  createChannelRequestSchema,
  deleteChannelRequestSchema,
  findRequestSchema,
  getChannelRequestSchema,
} from "@shared/validators";
import { SchemasMap } from "@shared/interfaces";
import { generateDynamicSchemaKeyString, mapToSchema } from "@shared/utils";

import { API, METHODS, SCHEMAS } from "../../../shared/constants";

const schemaShapes: SchemasMap<keyof typeof API.CHANNEL> = {
  [API_ENUM.GET_CHANNELS]: {
    request: {
      name: `${API.CHANNEL.GET_CHANNELS}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${API.CHANNEL.GET_CHANNELS}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: {},
    },
  },
  [API_ENUM.GET_CHANNEL]: {
    request: {
      name: `${API.CHANNEL.GET_CHANNEL}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: getChannelRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.CHANNEL.GET_CHANNEL("$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
  },
  [API_ENUM.FIND_SLACK_CHANNELS]: {
    request: {
      name: `${API.CHANNEL.FIND_SLACK_CHANNELS}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: findRequestSchema,
    },
    response: {
      name: `${API.CHANNEL.FIND_SLACK_CHANNELS}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: {},
    },
  },
  [API_ENUM.FIND_SLACK_USERS]: {
    request: {
      name: `${API.CHANNEL.FIND_SLACK_USERS}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: findRequestSchema,
    },
    response: {
      name: `${API.CHANNEL.FIND_SLACK_USERS}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: {},
    },
  },
  [API_ENUM.CREATE_CHANNEL]: {
    request: {
      name: `${API.CHANNEL.CREATE_CHANNEL}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: createChannelRequestSchema,
    },
    response: {
      name: `${API.CHANNEL.CREATE_CHANNEL}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: {},
    },
  },
  [API_ENUM.UPDATE_CHANNEL]: {
    request: {
      name: `${API.CHANNEL.UPDATE_CHANNEL}${SCHEMAS.REQUEST}${METHODS.PUT}`,
      schema: createChannelRequestSchema,
    },
    response: {
      name: `${API.CHANNEL.UPDATE_CHANNEL}${SCHEMAS.RESPONSE}${METHODS.PUT}`,
      schema: {},
    },
  },
  [API_ENUM.DELETE_CHANNEL]: {
    request: {
      name: `${API.CHANNEL.DELETE_CHANNEL}${SCHEMAS.REQUEST}${METHODS.DELETE}`,
      schema: deleteChannelRequestSchema,
    },
    response: {
      name: `${API.CHANNEL.DELETE_CHANNEL}${SCHEMAS.RESPONSE}${METHODS.DELETE}`,
      schema: {},
    },
  },
};

export default mapToSchema(schemaShapes);
