import React, { FC, useEffect } from "react";
import { generatePath } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { ExternalLinks, NameOfRoutes } from "@shared/constants";
import { LoadingSpinner } from "@shared/components";
import { actions as sharedActions } from "@shared/store";
import useQuery from "@shared/hooks/QueryHook";
import { selectors } from "@containers/Auth/store";
import { setAppCookie } from "@shared/utils";
import "./index.scss";

const InstallApplicationContainer: FC = () => {
  const dispatch = useDispatch();
  const authentificated = useSelector(selectors.authentificated());
  const query = useQuery();
  const autoLogin = query.get("autoLogin");
  const redirectStripe = query.get("redirectStripe");
  const coupon = query.get("coupon");

  useEffect(() => {
    if (coupon) {
      setAppCookie("coupon", coupon);
    }
    if (autoLogin) {
      if (authentificated) {
        dispatch(
          sharedActions.navigate(
            generatePath(redirectStripe ? NameOfRoutes.WORKSPACE + "?redirectStripe=true" : NameOfRoutes.WORKSPACE),
          ),
        );
      } else {
        const redirectLink = window.location.origin + NameOfRoutes.AUTH;
        window.location.href = `${ExternalLinks.SLACK_OAUTH}${redirectLink}`;
      }
    } else {
      const redirectLink = window.location.origin + NameOfRoutes.ADD_SLACK_WORKSPACE;
      window.location.href = `${ExternalLinks.SLACK_ADD_WORKSPACE}${redirectLink}`;
    }
  }, [dispatch, coupon, autoLogin, authentificated, redirectStripe]);

  return (
    <section className="install-application">
      <LoadingSpinner />
    </section>
  );
};

export default InstallApplicationContainer;
