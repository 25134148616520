import React, { useEffect, useState } from "react";
import { checkPermission } from "@shared/utils";

export interface AccessControlProps {
  permission: string;
  channel_id: number | null;
  children?: React.ReactNode;
}

const AccessControl = (props: AccessControlProps) => {
  const { permission, channel_id, children } = props;
  const [permitted, setPermitted] = useState(false);
  useEffect(() => {
    setPermitted(checkPermission(permission, channel_id));
  }, [permission, channel_id]);

  return permitted ? <>{children}</> : null;
};

export default AccessControl;
