import { createSelector } from "reselect";
import { AppState } from "@shared/interfaces";

const selectTopic = (state: AppState) => state.topic;

export const getTopics = () => createSelector(selectTopic, (state) => state.topics);
export const getTopic = () => createSelector(selectTopic, (state) => state.currentTopic);
export const getPackDetailed = () => createSelector(selectTopic, (state) => state.currentPackDetailed);
export const getPacks = () => createSelector(selectTopic, (state) => state.packs);
export const getPacksChannel = () => createSelector(selectTopic, (state) => state.packsChannel);
