import React, { useEffect, useState, useMemo, useCallback } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Select, Icon, Tooltip } from "@shared/components";
import { useDebounce } from "@shared/hooks";
import { OnChangeValue, MenuListProps, Props as SelectProps, MultiValueGenericProps } from "react-select";
import { getUniqueArray } from "@shared/utils";
import { AnyType } from "@shared/interfaces";
import "./index.scss";

export interface Option {
  label: string;
  value: string | number | null;
}

export interface AutocompleteMultiSelectProps extends Partial<SelectProps> {
  name: string;
  options: OnChangeValue<Option, true>;
  value: { label: string; value: string }[];
  getData: (params: string, page?: number) => void;
  handleRemoveValue?: (id: string) => void;
  onLockedOptionClick?: (option: Option) => void;
  placeholder?: string;
  className?: string;
  isDisabled?: boolean;
  menuWrapper?: (props: MenuListProps) => JSX.Element;
  multiValueContainer?: (props: MultiValueGenericProps) => JSX.Element;
  isLockedLogic?: boolean;
  availableOptions?: number[];
  tooltipText?: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prepareOptionFunction?: (element: any) => any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectData: () => any;
  selectTotalCount?: () => AnyType;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (option: any) => void;
  hideValues?: boolean;
  hideSelectedOptions?: boolean;
  staticOptions?: OnChangeValue<Option, true>;
  lazyLoad?: boolean;
}

export function AutocompleteMultiSelect(props: AutocompleteMultiSelectProps) {
  const {
    selectData,
    getData,
    prepareOptionFunction,
    value,
    handleRemoveValue,
    menuWrapper,
    isLockedLogic,
    availableOptions = [],
    tooltipText,
    hideValues,
    staticOptions,
    onLockedOptionClick,
    noOptionsMessage,
    selectTotalCount,
    lazyLoad = true,
    ...selectProps
  } = props;
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const suggestions: any = useSelector(selectData());
  const total: AnyType = useSelector(selectTotalCount ? selectTotalCount() : () => 0);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [page, setPage] = useState(0);
  const [options, setOptions] = useState(selectProps.options || []);
  const [inputValue, setInputValue] = useState("");

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const onOptionClick = useCallback(
    (isLockedOption: boolean, option: Option) => {
      if (isLockedOption && onLockedOptionClick) {
        onLockedOptionClick(option);
      }
    },
    [onLockedOptionClick],
  );

  const selectedValues = useMemo(() => {
    return value.map(({ value, label }, index) => {
      const isLocked = Boolean(isLockedLogic && !availableOptions.find((v) => v === Number(value)));
      const key = `${value}_${label}_${index}`;

      const item = (
        <div
          key={key}
          onClick={() => onOptionClick(isLocked, { value, label })}
          className={classnames("multi_select_value_item", {
            is_locked: isLocked,
          })}>
          <div className="lock-icon">
            <Icon type="lock" />
          </div>
          <div>{label}</div>
          {!props.isDisabled && (
            <div className="multi_select_value_close" onClick={() => handleRemoveValue && handleRemoveValue(value)}>
              <Icon type="clear" />
            </div>
          )}
        </div>
      );

      return isLocked && tooltipText ? (
        <Tooltip key={key} content={tooltipText}>
          {item}
        </Tooltip>
      ) : (
        item
      );
    });
  }, [value, handleRemoveValue, isLockedLogic, availableOptions, props.isDisabled, tooltipText, onOptionClick]);

  useEffect(() => {
    if (prepareOptionFunction) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newSuggestions = suggestions.map((s: any) => prepareOptionFunction(s));
      const options: Option[] = staticOptions ? [...staticOptions, ...newSuggestions] : newSuggestions;
      setOptions(getUniqueArray(options, "value"));
    }
  }, [suggestions, prepareOptionFunction, staticOptions]);

  useEffect(() => {
    if (!props.isDisabled) {
      dispatch(getData(debouncedSearchQuery, page));
    }
  }, [debouncedSearchQuery, dispatch, getData, props.isDisabled, page]);

  const onMenuScrollToBottom = useCallback(() => {
    if (lazyLoad && total && suggestions.length < total) {
      setPage((prev) => prev + 1);
    }
  }, [lazyLoad, total, suggestions]);

  return (
    <div className="autocomplete-select-wrapper">
      {!props.isDisabled && (
        <Select
          {...selectProps}
          value={value}
          options={options}
          isMulti={true}
          onInputChange={(query, { action }) => {
            setSearchQuery(query);
            if (action !== "set-value" && action !== "menu-close") {
              setInputValue(query);
            }
          }}
          placeholder={selectProps.placeholder || "Start typing here"}
          noOptionsMessage={noOptionsMessage || (() => "No matches found")}
          menuWrapper={menuWrapper}
          onMenuScrollToBottom={onMenuScrollToBottom}
          isClearable={props.isClearable || false}
          blurInputOnSelect={false}
          inputValue={inputValue}
        />
      )}
      {!hideValues && <div className="multi_select_values_wrapper">{selectedValues}</div>}
    </div>
  );
}

export default AutocompleteMultiSelect;
